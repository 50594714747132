import React, { useEffect, useState } from 'react';
import styles from './index.module.sass';
import superagent from 'superagent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';

const Reconstruye = (props) => {
  const { screenSize, webservice } = props;
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('creatingSmiles');

  useEffect(() => {
    setLoading(true);
    async function showVideos() {
      await superagent
        .get(`${webservice}/getVideos`)
        .set('Content-Type', 'application/json')
        .then((res) => {
          const { body = [] } = res;
          setLoading(false);
          if (body.length > 0) {
            for (let index = 0; index < body.length; index++) {
              if (body[index].categoria === 'Creando sonrisas') {
                setVideos(body[index].videos);
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(true);
        });
    }
    showVideos();
  }, [webservice]);

  return (
    <div id={styles.Videos} className={styles[screenSize]}>
      <div className={styles.title}>{t('titleReconstruye')}</div>
      {(loading && (
        <div className={styles.loading}>
          <CircularProgress className={styles.circularProgressStyle} />
        </div>
      )) || (
        <>
          <div className={styles.thumbsContainer}>
            <div className={styles.thumbs}>
              {videos.map((val, key) => {
                const { enlace = '' } = val;
                return (
                  <div className={styles.videoContainer} key={key}>
                    <iframe
                      width='95%'
                      height='300'
                      src={`https://www.youtube.com/embed/${enlace}?rel=0&amp;showinfo=0`}
                      frameBorder='0'
                      allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                      allowFullScreen='allowfullscreen'
                      className={styles.youtube}
                      title='TMBVideos'></iframe>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Reconstruye;
