import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './index.module.sass';

//Objects
import taxonomy from './menuObject';

//Dependencies
import buttonFormROP from '../VerifyButtonROP/buttonROP.js';

const Menu = (props) => {
  const { device, open, setOpen, history = {} } = props;
  const { location = {} } = history;
  const { hash = ' ' } = location;
  const [itemSelected, setItemSelected] = useState(null);
  const screenSize = device.screenSize;
  const { t } = useTranslation(['menu', 'home']);
  const [buttonROP, setButtonROP] = useState(false);

  useEffect(() => {
    async function loadROP() {
      const response = await buttonFormROP();
      setButtonROP(response);
    }
    loadROP();
  }, [buttonROP]);

  return (
    <>
      {open && screenSize !== 'desktop' && (
        <div className={styles.overlay} onClick={() => setOpen(false)} />
      )}
      <div id={styles.departaments} className={`${styles[screenSize]} ${styles[open]}`}>
        {screenSize === 'phone' && (
          <div className={styles.departamentContainer}>
            <div
              className={`${styles.departament}`}
              onClick={(e) => {
                setOpen(false);
                window.open('http://carrito.terramarbrands.com.mx', '_self');
              }}>
              <span>{t('home:cartName')}</span>
            </div>

            {buttonROP ? (
              <div
                className={`${styles.departament}`}
                onClick={(e) => {
                  setOpen(false);
                  window.open('/registrationForm', '_self');
                }}>
                <span>REGISTRATE AQUÍ</span>
              </div>
            ) : (
              <div className={styles.divHidden}></div>
            )}
          </div>
        )}
        {taxonomy.map((val, key) => {
          return (
            <div
              className={styles.departamentContainer}
              key={key}
              onMouseEnter={() => {
                if (screenSize === 'desktop') setItemSelected(key);
              }}
              onMouseLeave={() => {
                if (screenSize === 'desktop') setItemSelected(null);
              }}
              onClick={() => {
                if (key === itemSelected) setItemSelected(null);
                else setItemSelected(key);
              }}
              style={{
                order: val.index,
                width: `${(screenSize !== 'phone' && 100 / taxonomy.length) || 100}%`,
              }}>
              <Link
                to={`${val.url}${hash}`}
                className={`${styles.departament} ${styles[key === itemSelected]} ${
                  val.children.length > 0 && styles.haveChildren
                }`}
                onClick={(e) => {
                  if (val.children.length > 0) e.preventDefault();
                  else setOpen(false);
                }}>
                <span>{t(val.id)}</span>
              </Link>
              <div
                className={`${styles.families} ${
                  styles[itemSelected === key && val.children.length > 0]
                }`}>
                {taxonomy[key].children.map((subval, subkey) => {
                  if (subval.external) {
                    return (
                      <a
                        className={styles.familyTitle}
                        key={subkey}
                        href={`${subval.url}`}
                        target='_blank'
                        rel='noopener noreferrer'
                        onClick={() => {
                          setItemSelected(null);
                          setOpen(false);
                        }}>
                        {t(subval.id)}
                      </a>
                    );
                  } else {
                    return (
                      <Link
                        className={styles.familyTitle}
                        key={subkey}
                        to={`${val.url}${subval.url}${hash}`}
                        onClick={() => {
                          setItemSelected(null);
                          setOpen(false);
                        }}>
                        {t(subval.id)}
                      </Link>
                    );
                  }
                })}
              </div>
            </div>
          );
        })}
        <div
          className={styles.departamentContainer}
          style={{
            order: 20,
          }}></div>
      </div>
    </>
  );
};

export default Menu;
