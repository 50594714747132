import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Styles
import styles from './index.module.sass';

export default function CategoriesCarousel({ screenSize = 'desktop', history }) {
  const sliderRef = useRef();
  const [dragging, setDraggin] = useState(false);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: (screenSize === 'phone' && 1) || (screenSize === 'tablet' && 3) || 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    beforeChange: () => {
      setDraggin(true);
    },
    afterChange: () => {
      setDraggin(false);
    },
  };

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  function onClick(route) {
    if (dragging) return;
    if (history) {
      window.scrollTo(0, 300);
      history.push(route);
    }
  }

  return (
    <div id={styles.CategoriesCarousel} className={styles[screenSize]}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>CATEGORÍAS</div>
        <div className={styles.subtitle}>de productos</div>
      </div>
      <div className={styles.container}>
        <div className={styles.sliderContainer}>
          <Slider {...settings} className={styles.slider} ref={sliderRef}>
            <div className={styles.category}>
              <div className={styles.slideContainer} onClick={() => onClick('/products/5')}>
                <div
                  className={styles.bgImage}
                  style={{
                    backgroundImage: `url(https://webimages.terramarbrands.com.mx/usawebpage/home/cat-01.jpg)`,
                  }}
                />
                <div className={styles.slideContent}>
                  <div className={styles.categoryName}>Cuidado de la piel</div>
                </div>
              </div>
            </div>

            <div className={styles.category}>
              <div className={styles.slideContainer} onClick={() => onClick('/products/3')}>
                <div
                  className={styles.bgImage}
                  style={{
                    backgroundImage: `url(https://webimages.terramarbrands.com.mx/usawebpage/home/cat-02.jpg)`,
                  }}
                />
                <div className={styles.slideContent}>
                  <div className={styles.categoryName}>Cuidado capilar</div>
                </div>
              </div>
            </div>

            <div className={styles.category}>
              <div className={styles.slideContainer} onClick={() => onClick('/products/8')}>
                <div
                  className={styles.bgImage}
                  style={{
                    backgroundImage: `url(https://webimages.terramarbrands.com.mx/usawebpage/home/cat-03.jpg)`,
                  }}
                />
                <div className={styles.slideContent}>
                  <div className={styles.categoryName}>Cuidado personal e higiene íntima</div>
                </div>
              </div>
            </div>

            <div className={styles.category}>
              <div className={styles.slideContainer} onClick={() => onClick('/products/6')}>
                <div
                  className={styles.bgImage}
                  style={{
                    backgroundImage: `url(https://webimages.terramarbrands.com.mx/usawebpage/home/cat-04.jpg)`,
                  }}
                />
                <div className={styles.slideContent}>
                  <div className={styles.categoryName}>Cuidado del cuerpo</div>
                </div>
              </div>
            </div>

            <div className={styles.category}>
              <div className={styles.slideContainer} onClick={() => onClick('/products/1')}>
                <div
                  className={styles.bgImage}
                  style={{
                    backgroundImage: `url(https://webimages.terramarbrands.com.mx/usawebpage/home/cat-05.jpg)`,
                  }}
                />
                <div className={styles.slideContent}>
                  <div className={styles.categoryName}>Maquillaje</div>
                </div>
              </div>
            </div>

            <div className={styles.category}>
              <div className={styles.slideContainer} onClick={() => onClick('/products/2')}>
                <div
                  className={styles.bgImage}
                  style={{
                    backgroundImage: `url(https://webimages.terramarbrands.com.mx/usawebpage/home/cat-06.jpg)`,
                  }}
                />
                <div className={styles.slideContent}>
                  <div className={styles.categoryName}>Fragancias</div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
        {screenSize !== 'phone' && (
          <>
            <div className={`${styles.arrow} ${styles.leftArrow}`} onClick={() => previous()}>
              <i className={`material-icons-outlined ${styles.arrowIcon}`}>navigate_before</i>
            </div>
            <div className={`${styles.arrow} ${styles.rightArrow}`} onClick={() => next()}>
              <i className={`material-icons-outlined ${styles.arrowIcon}`}>navigate_next</i>
            </div>
          </>
        )}
      </div>

      {screenSize === 'phone' && (
        <div className={styles.mobileArrows}>
          <div className={`${styles.arrow} ${styles.leftArrow}`} onClick={() => previous()}>
            <i className={`material-icons-outlined ${styles.arrowIcon}`}>navigate_before</i>
          </div>
          <div className={`${styles.arrow} ${styles.rightArrow}`} onClick={() => next()}>
            <i className={`material-icons-outlined ${styles.arrowIcon}`}>navigate_next</i>
          </div>
        </div>
      )}
    </div>
  );
}
