import React, { useState, useEffect, useCallback } from 'react';

import styles from './index.module.sass';

const Contador = (props) => {
  const { device } = props;
  const { screenSize } = device;
  const [days, setDays] = useState('');
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [seconds, setSeconds] = useState('');
  const [show, setshow] = useState(true);

  const second = 1000;
  const minute = second * 60;
  const hour = minute * 60;
  const day = hour * 24;

  const updateCountdown = useCallback(() => {
    const target = new Date('10/04/2022 12:01 AM');
    const now = new Date();
    if (now < target) {
      const duration = target - now;
      const remaining_days = Math.floor(duration / day);
      const remaining_hours = Math.floor((duration % day) / hour);
      const remaining_minutes = Math.floor((duration % hour) / minute);
      const remaining_seconds = Math.floor((duration % minute) / second);

      setDays(remaining_days);
      setHours(remaining_hours);
      setMinutes(remaining_minutes);
      setSeconds(remaining_seconds);
    } else {
      setshow(false);
    }
  }, [day, hour, minute]);

  useEffect(() => {
    updateCountdown();
    setInterval(updateCountdown, second);
  }, [updateCountdown]);

  return (
    <div className={`${styles[screenSize]} ${styles.fondo}`}>
      {show && (
        <div className={`${styles[screenSize]} ${styles.container}`}>
          <div className={styles.textos}>Faltan</div>
          <div className={`${styles[screenSize]} ${styles.contador}`}>
            <div className={`${styles[screenSize]} ${styles.square}`}>
              <div className={`${styles[screenSize]} ${styles.text}`}>{days}</div>
              <div className={`${styles[screenSize]} ${styles.subText}`}>Días</div>
            </div>
            <div className={`${styles[screenSize]} ${styles.separator}`}>:</div>
            <div className={`${styles[screenSize]} ${styles.square}`}>
              <div className={`${styles[screenSize]} ${styles.text}`}>{hours}</div>
              <div className={`${styles[screenSize]} ${styles.subText}`}>Horas</div>
            </div>
            <div className={`${styles[screenSize]} ${styles.separator}`}>:</div>
            <div className={`${styles[screenSize]} ${styles.square}`}>
              <div className={`${styles[screenSize]} ${styles.text}`}>{minutes}</div>
              <div className={`${styles[screenSize]} ${styles.subText}`}>
                {screenSize === 'phone' ? 'Min' : 'Minutos'}
              </div>
            </div>
            <div className={`${styles[screenSize]} ${styles.separator}`}>:</div>
            <div className={`${styles[screenSize]} ${styles.square}`}>
              <div className={`${styles[screenSize]} ${styles.text}`}>{seconds}</div>
              <div className={`${styles[screenSize]} ${styles.subText}`}>
                {screenSize === 'phone' ? 'Seg' : 'Segundos'}
              </div>
            </div>
          </div>

          <div>
            <div className={`${styles[screenSize]} ${styles.textos}`}>
              para nuestra esperada apertura
            </div>
            <div className={`${styles[screenSize]} ${styles.textGold}`}>DE TERRAMAR USA!</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contador;
