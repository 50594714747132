import React from 'react';
import styles from './index.module.sass';

const PrivacyNotice = () => {
  return (
    <div className={styles.Text}>
      <h2>Aviso de privacidad</h2>
      <p>
        {' '}
        Con fundamento en los artículos 15 y 16 de la Ley Federal de Protección de Datos Personales
        en Posesión de Particulares hacemos de su conocimiento que Terramar Brands, S. de R.L. de
        C.V., con domicilio en Norte 45 no. 1017 Local 11, Colonia Industrial Vallejo, México, DF,
        Delegación Azcapotzalco, CP 02300 es responsable de recabar sus datos personales, del uso
        que se le dé a los mismos y de su protección.
      </p>

      <p>
        {' '}
        Su información personal será utilizada para las siguientes finalidades: proveer los
        servicios y productos que ha solicitado; notificarle sobre nuevos servicios o productos que
        tengan relación con los ya contratados o adquiridos; comunicarle sobre cambios en los
        mismos; elaborar estudios y programas que son necesarios para determinar hábitos de consumo;
        realizar evaluaciones periódicas de nuestros productos y servicios a efecto de mejorar la
        calidad de los mismos; evaluar la calidad del servicio que brindamos, mensajería, cobranza,
        en general, para dar cumplimiento a las obligaciones que hemos contraído con usted.
      </p>

      <p>
        Para las finalidades antes mencionadas, requerimos obtener los siguientes datos personales:
      </p>

      <p>
        • Nombre completo <br /> • Edad <br /> • Estado civil <br /> • Sexo <br /> • Teléfono fijo
        y/o celular <br /> • Correo electrónico <br /> • ID de Facebook, Twitter y/o Linkedin <br />{' '}
        • Firma autógrafa <br /> • Dirección <br /> • RFC y/o CURP
      </p>

      <p>
        Es importante informarle que usted tiene derecho al Acceso, Rectificación y Cancelación de
        sus datos personales, a Oponerse al tratamiento de los mismos o a revocar el consentimiento
        que para dicho fin nos haya otorgado.
      </p>

      <p>
        {' '}
        Para ello, es necesario que envíe la solicitud en los términos que marca la Ley en su
        Artículo 29 al gerente de sistemas, responsable de nuestro Departamento de Protección de
        Datos Personales, ubicado en Norte 45 no. 1017 Local 11, Colonia Industrial Vallejo, México,
        DF, Delegación Azcapotzalco, CP 02300 México, o bien, se comunique al teléfono 50781600 o
        vía correo electrónico a soporte@terramarbrands.com, el cual solicitamos confirme vía
        telefónica para garantizar su correcta recepción.
      </p>
      <p>
        {' '}
        Por otra parte, hacemos de su conocimiento que sus datos podrán ser transferidos a entidades
        del mismo grupo de interés de la empresa, nacionales o extranjeras, con el objetivo general
        de cumplir con las finalidades para las cuales ha proporcionado sus datos. Propósitos
        específicos: mejorar servicio, prestadores de servicios, incrementar los servicios, entre
        otros.
      </p>
      <p>
        {' '}
        En éste sentido su información podrá ser compartida con las empresas filiales o relacionadas
        con el medio.
      </p>
      <p>
        {' '}
        En caso de que no obtengamos su oposición expresa para que sus datos personales sean
        transferidos en la forma y términos antes descrita, entenderemos que ha otorgado su
        consentimiento en forma tácita para ello.
      </p>
      <p>
        {' '}
        En caso de que no desee de recibir mensajes promocionales de nuestra parte, puede enviarnos
        su solicitud por medio de la dirección electrónica: soporte@terramarbrands.com
      </p>
      <p>
        {' '}
        Importante: Cualquier modificación a este Aviso de Privacidad podrá consultarlo en: &nbsp;
        <a href='https://www.terramarbrands.com.mx/'>https://www.terramarbrands.com.mx/</a>
      </p>
    </div>
  );
};

export default PrivacyNotice;
