import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './index.module.sass';

//Imges
import ICON1 from '../icons/footer_1.svg';
import ICON2 from '../icons/footer_2.svg';
import ICON3 from '../icons/footer_3.svg';
import ICON4 from '../icons/footer_4.svg';
import ICON5 from '../icons/footer_5.svg';

const Prefooter = (props) => {
  const { device } = props;
  const { screenSize } = device;
  const { t } = useTranslation('footer');
  const ICON6 =
    'https://webimages.terramarbrands.com.mx/webpage/creandoSonrisas/creando-sonrisas-white.svg';
  return (
    <div className={`${styles.prefooter} ${styles[screenSize]}`}>
      <Link className={styles.prefooterOption} to='/responsibility'>
        <div className={styles.icon}>
          <img src={ICON1} alt='' />
        </div>
        <div className={styles.title}>{t('prefooter.responsibility')}</div>
      </Link>
      <Link className={styles.prefooterOption} to='/consultancy'>
        <div className={styles.icon}>
          <img src={ICON2} alt='' />
        </div>
        <div className={styles.title}>{t('prefooter.shipping')}</div>
      </Link>
      <Link className={styles.prefooterOption} to='/products'>
        <div className={styles.icon}>
          <img src={ICON3} alt='' />
        </div>
        <div className={styles.title}>{t('prefooter.products')}</div>
      </Link>
      <Link className={styles.prefooterOption} to='/contact'>
        <div className={styles.icon}>
          <img src={ICON4} alt='' />
        </div>
        <div className={styles.title}>{t('prefooter.contact')}</div>
      </Link>
      <Link className={styles.prefooterOption} to='/faq'>
        <div className={styles.icon}>
          <img src={ICON5} alt='' />
        </div>
        <div className={styles.title}>{t('prefooter.faq')}</div>
      </Link>
      <Link className={styles.prefooterOption} to='/creandoSonrisas'>
        <div className={styles.icon}>
          <img src={ICON6} alt='' />
        </div>
        <div className={styles.title}>{t('prefooter.creatingSmiles')}</div>
      </Link>
    </div>
  );
};

export default Prefooter;
