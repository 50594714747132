import React, { useState } from 'react';
import styles from './index.module.sass';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

//Dependencies
import superagent from 'superagent';

//Components
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';
import Alert from '../Alert';

const useStyles = makeStyles({
  root: {
    width: '100%',
    backgroundColor: '#9eadb9',
    color: '#000000',
    borderRadius: '5px',
    '&:hover .MuiOutlinedInput-input': {
      color: '#000000',
    },
    '&:hover .MuiInputLabel-root': {
      color: '#000000',
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#ffffff',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
      color: '#002747',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: '#ffffff',
      backgroundColor: '#002747',
      padding: '2px',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#002747',
    },
    '& .MuiInputLabel-root.MuiInputLabel-shrink': {
      color: '#ffffff',
      backgroundColor: '#002747',
      padding: '2px',
    },
  },
});

const ContactForm = (props) => {
  const { webservice, leaderSelected } = props;

  const { t } = useTranslation('joinus');

  const [name, setName] = useState('');
  const [mail, setMail] = useState('');
  const [cellphone, setCellphone] = useState('');
  const [city, setCity] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const Send = () => {
    setLoading(true);
    const { email = '', nombre = '' } = leaderSelected;
    const Form = {
      nombreDestino: nombre || 'Únete Terramar',
      emailDestino: email || 'unete@terramarbrands.com',
      nombreRemitente: name,
      emailRemitente: mail,
      celular: cellphone,
      ciudad: city,
      mensaje: message,
    };

    superagent
      .post(`${webservice}/postEnviarEmail`)
      .set(('Content-Type': 'application/json'))
      .send(Form)
      .then((res) => {
        console.log(res);
        const { body = {} } = res;
        console.log(body);
        const { mensaje = '' } = body;
        setAlertText(mensaje);
        setOpen(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setAlertText(t('sendError'));
        setOpen(true);
        setLoading(false);
      });
  };

  return (
    <div className={styles.contactForm}>
      <Alert open={open} setOpen={setOpen} title={'Terramar'} text={alertText} />
      <div className={styles.formContainer}>
        <div className={styles.contactTitle}>{t('areYouNew')}</div>
        <div className={styles.contactSubtitle}>{t('contactText')}</div>
        {leaderSelected && leaderSelected.nombre && (
          <div className={styles.leaderSelected}>
            {t('reach')} {leaderSelected.nombre}
          </div>
        )}
        <div className={`${styles.inputContainer} ${styles.contactInput}`}>
          <TextField
            id='nameform-outlined-code-input'
            label={t('contactName')}
            type='text'
            variant='outlined'
            className={classes.root}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className={`${styles.inputContainer} ${styles.contactInput}`}>
          <TextField
            id='mail-outlined-code-input'
            label={t('contactMail')}
            type='text'
            variant='outlined'
            className={classes.root}
            value={mail}
            onChange={(e) => setMail(e.target.value)}
          />
        </div>

        <div className={`${styles.inputContainer} ${styles.contactInput}`}>
          <TextField
            id='cellphone-outlined-code-input'
            label={t('contactCell')}
            type='text'
            variant='outlined'
            className={classes.root}
            value={cellphone}
            onChange={(e) => setCellphone(e.target.value)}
          />
        </div>

        <div className={`${styles.inputContainer} ${styles.contactInput}`}>
          <TextField
            id='city-outlined-code-input'
            label={t('contactCity')}
            type='text'
            variant='outlined'
            className={classes.root}
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </div>

        <div className={`${styles.inputContainer} ${styles.contactInput}`}>
          <TextField
            id='message-outlined-code-input'
            label={t('contactMessage')}
            type='text'
            variant='outlined'
            className={classes.root}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>

        <div className={styles.buttonsContainer}>
          <Button
            type='submit'
            variant='contained'
            size='large'
            color='primary'
            className={`${styles.button} ${styles.messageButton}`}
            onClick={() => Send()}>
            {(loading && <CircularProgress />) || t('send')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
