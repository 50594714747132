const menu = [
  {
    id: 'home',
    index: 0,
    parent: null,
    url: '/',
    children: [],
  },
  {
    id: 'services',
    index: 0,
    parent: null,
    url: '/services',
    children: [
      // {
      //   id: 'terraweb',
      //   index: 0,
      //   external: true,
      //   url: 'https://terramarbrands.mx/terraweb-tmb',
      //   children: [],
      // },
      // {
      //   id: 'attentions',
      //   index: 1,
      //   external: true,
      //   url: 'https://atenciones.terramarbrands.com.mx/',
      //   children: [],
      // },
      {
        id: 'admin',
        index: 1,
        external: true,
        url: 'https://admin.terramarbrands.com.mx/',
        children: [],
      },
      // {
      //   id: 'atthewheel',
      //   index: 1,
      //   external: true,
      //   url: 'https://alvolante.terramarbrands.com.mx/',
      //   children: [],
      // },
    ],
  },
  {
    id: 'products',
    index: 1,
    parent: null,
    url: '/products',
    children: [],
  },
  {
    id: 'catalog',
    index: 1,
    parent: null,
    url: '/catalog',
    children: [],
  },
  {
    id: 'about',
    index: 1,
    parent: null,
    url: '/about',
    children: [],
  },
  {
    id: 'joinus',
    index: 1,
    parent: null,
    url: '/joinus',
    children: [],
  },
  {
    id: 'career',
    index: 1,
    parent: null,
    url: '/career',
    children: [],
  },
  // {
  //   id: 'consultancy',
  //   index: 1,
  //   parent: null,
  //   url: '/consultancy',
  //   children: [],
  // },
  {
    id: 'videos',
    index: 1,
    parent: null,
    url: '/videos',
    children: [],
  },
];

export default menu;
