import React, { useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './index.module.sass';

//Dependencies
import superagent from 'superagent';
import Parser from 'html-react-parser';

//Components
import { CircularProgress } from '@material-ui/core';

const Product = (props) => {
  const { webservice, device } = props;
  const { screenSize } = device;
  let { id = '' } = useParams();

  const [loading, setLoading] = useState(false);
  const [productID, setProductID] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [ingredients, setIngredients] = useState('');
  const [application, setApplication] = useState('');

  const getDescriptions = useCallback(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    const url = `${webservice}/getDescripciones?depto=0&clave=${id}`;
    superagent
      .get(url)
      .set('Content-Type', 'application/json')
      .then((res) => {
        const { body = {} } = res;
        const tmpProduct = (body.length > 0 && body[0]) || {};

        const {
          aplicacion = '',
          descripcion = '',
          clave = '',
          producto = '',
          ingredientes = '',
        } = tmpProduct;
        setProductID(clave);
        setName(producto);
        setDescription(descripcion);
        setIngredients(ingredientes);
        setApplication(aplicacion);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [webservice, id]);

  useEffect(() => {
    getDescriptions();
  }, [getDescriptions]);

  return (
    <div id={styles.Product} className={styles[screenSize]}>
      {(loading && <CircularProgress />) || (
        <>
          <div className={`${styles.imageContainer} ${styles[screenSize]}`}>
            <img
              src={`https://webimages.terramarbrands.com.mx/shopping-cart/cart-products/${productID}.jpg`}
              alt={name}
              className={styles.imageProduct}
            />
          </div>
          <div className={styles.dataContainer}>
            <div className={styles.productName}>{name}</div>
            <div className={styles.dataContent}>
              <div className={styles.data}>
                <div className={styles.textContainer}>
                  <div className={styles.title}>Descripción</div>
                  <div className={styles.info}>{Parser(description)}</div>
                </div>
              </div>
              <div className={styles.data}>
                <div className={styles.textContainer}>
                  <div className={styles.title}>Ingredientes</div>
                  <div className={styles.info}>{Parser(ingredients)}</div>
                </div>
              </div>
              <div className={styles.data}>
                <div className={styles.textContainer}>
                  <div className={styles.title}>Aplicación</div>
                  <div className={styles.info}>{Parser(application)}</div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Product;
