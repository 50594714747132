import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.sass';

import GonzaloSr from '../../../images/about/gonzalosr.png';
import GonzaloJr from '../../../images/about/gonzalojr.png';
import RonClark from '../../../images/about/ronclarck.png';

const Pestanas = (props) => {
  const { device } = props;
  const { screenSize = 'desktop' } = device;
  const { t } = useTranslation('about');
  const [shotTab, setShowTab] = useState('gonzalosr');

  return (
    <div id={styles.Pestanas} className={styles[screenSize]}>
      <div className={styles.tabsContainer}>
        <div className={styles.tabs}>
          <div
            className={`${styles.pestana} ${styles[shotTab === 'gonzalosr']} ${styles.first}`}
            onClick={() => setShowTab('gonzalosr')}>
            Gonzalo Rubio Senior
          </div>
          <div
            className={`${styles.pestana} ${styles[shotTab === 'gonzalojr']}`}
            onClick={() => setShowTab('gonzalojr')}>
            Gonzalo Rubio Jr.
          </div>
          <div
            className={`${styles.pestana} ${styles[shotTab === 'ronclark']} ${styles.last}`}
            onClick={() => setShowTab('ronclark')}>
            Ron Clark.
          </div>
        </div>
        {shotTab === 'gonzalosr' && (
          <div id='gonzalosr' className={`${styles.tab}`}>
            <div className={styles.imageContainer}>
              <img className={styles.media} src={GonzaloSr} alt='Gonzalo Sr' />
            </div>
            <h3>Gonzalo Rubio Senior</h3>
            <h5>{t('tabs.gonzalosr')}</h5>
          </div>
        )}
        {shotTab === 'gonzalojr' && (
          <div id='gonzalojr' className={`${styles.tab}`}>
            <div className={styles.imageContainer}>
              <img className={styles.media} src={GonzaloJr} alt='Gonzalo Jr' />
            </div>
            <h3>Gonzalo Rubio Junior</h3>
            <h5>{t('tabs.gonzalojr')}</h5>
          </div>
        )}
        {shotTab === 'ronclark' && (
          <div id='ronclark' className={`${styles.tab}`}>
            <div className={styles.imageContainer}>
              <img className={styles.media} src={RonClark} alt='Ron Clark' />
            </div>
            <h3>Ron Clark</h3>
            <h5>{t('tabs.ronclark')}</h5>
          </div>
        )}
      </div>
    </div>
  );
};

export default Pestanas;
