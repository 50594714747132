import React from 'react';
import styles from './index.module.sass';

const TermsOfUse = () => {
  return (
    <div className={styles.Text}>
      <h2>Términos y condiciones</h2>

      <p>
        {' '}
        El uso de este SITIO web implica la aceptación de los TÉRMINOS Y CONDICIONES Y LAS POLÍTICAS
        DE PRIVACIDAD de TERRAMAR BRANDS S. DE R.L. DE C.V.
      </p>

      <p>
        {' '}
        El usuario debe leer, entender y aceptar todas las condiciones establecidas en los Términos
        y Condiciones Generales y en las Políticas de Privacidad así como en los demás documentos
        incorporados a los mismos por referencia, previo a su inscripción como Usuario de TERRAMAR
        BRANDS, S. DE R.L. DE C.V. TERMINOS Y CONDICIONES DEL USO DEL SITIO
      </p>
      <p>
        {' '}
        Este contrato describe los términos y condiciones generales (los “Términos y Condiciones
        Generales”) aplicables al uso de los servicios ofrecidos por TERRAMAR BRANDS S. DE R.L. DE
        C.V., (“los Servicios”) dentro del SITIO www.TERRAMARBRANDS.COM (el “SITIO”). TERRAMAR
        BRANDS, S. DE R.L. DE C.V. y el SITIO serán referidos indistintamente, como “TERRAMAR”.
        Cualquier persona que desee ingresar, acceder y/o usar el SITIO o los servicios podrá
        hacerlo sujetándose a los Términos y Condiciones Generales junto con las demás políticas y
        principios que rigen a TERRAMAR BRANDS S. DE R.L. DE C.V., y que son incorporados al
        presente. CUALQUIER PERSONA QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES GENERALES, CONTRATOS
        O DEMÁS INSTRUMENTOS QUE TENGAN EL CARÁCTER DE OBLIGATORIO Y VINCULANTE PARA LA RELACIÓN
        MERCANTIL ENTRE TERRAMAR BRANDS S. DE R.L. DE C.V., Y LOS USUARIOS, DEBERÁ DE ABSTENERSE DE
        UTILIZAR EL SITIO Y/O LOS SERVICIOS. AL USAR EL SITIO EL USUARIO ACEPTA ESTE CONVENIO Y
        DECLARA EXPRESAMENTE SU ACEPTACIÓN, MANIFESTANDO SU VOLUNTAD EN TÉRMINOS DE LO ESTABLECIDO
        POR EL ARTÍCULO 1803 Y 1834 BIS DEL CÓDIGO CIVIL FEDERAL, 80, 81 Y 89 DEL CÓDIGO DE COMERCIO
        Y DEMÁS RELATIVOS Y APLICABLES DE LA LEGISLACIÓN VIGENTE. RECONOCIENDO ASI MISMO QUE
        “TERRAMAR” SE RESERVA EL DERECHO DE SUSPENDER, NEGAR O CANCELAR EL SERVICIO Y CONTRATOS
        CUANDO ASÍ LO CONSIDERE, INCLUYENDO EL PRESENTE CONVENIO DE ADHESIÓN PARA EL USO DEL SITIO
        DE INTERNET QUE CELEBRAN “TERRAMAR” Y EL USUARIO, SUJETÁNDOSE AMBAS PARTES A LO ESTABLECIDO
        EN EL PRESENTE CONVENIO. Además el usuario reconoce y acepta que este y no “TERRAMAR”, es
        responsable de obtener y mantener todas las telecomunicaciones, enlaces de ancho de banda y
        equipos de cómputo y servicios necesarios para accesar y usar el SITIO y o pagar todos los
        cargos relacionados con el mismo.
      </p>

      <p>
        {' '}
        1.-­‐CAPACIDAD Los Servicios solo estarán disponibles para las personas que tengan capacidad
        legal para contratar. No podrán utilizar los servicios las personas que no tengan capacidad
        legal, los menores de edad deberán abstenerse de suministrar información personal para ser
        incluida en la base de datos.
      </p>

      <p>
        {' '}
        Tampoco podrán utilizar los servicios aquellos usuarios de “TERRAMAR”, que hayan sido
        suspendidos o inhabilitados temporal o definitivamente.
      </p>

      <p>
        {' '}
        2.-­‐ INSCRIPCIÓN Es obligatorio completar el formulario de inscripción y/o el contrato en
        todos sus campos con datos válidos para poder utilizar los servicios que brinda “TERRAMAR”.
        El futuro Usuario deberá completarlo con su información personal de manera exacta, precisa y
        verdadera (“Datos Personales”) y asume el compromiso de utilizar los Datos Personales
        conforme resulte necesario. “TERRAMAR” podrá utilizar diversos medios para identificar sus
        usuarios pero no se responsabiliza por la certeza de los Datos Personales proporcionados por
        los Usuarios. Los usuarios garantizan y responden, en cualquier caso de la veracidad,
        exactitud, vigencia y autenticidad de los Datos Personales ingresados. “TERRAMAR” se reserva
        el derecho de solicitar algún comprobante y/o dato adicional a efectos de corroborar los
        Datos Personales, así como de suspender temporal o definitivamente a aquellos Usuarios cuyos
        datos no hayan podido ser confirmados sin que ello genere ningún tipo de resarcimiento. El
        usuario accederá a su cuenta personal (“Cuenta”) mediante el ingreso de su clave de usuario
        que se compondrá por las letras TE más el número de kit y contraseña personal elegida
        (“Clave de Seguridad”). El usuario es el único responsable de mantener la confidencialidad
        de su clave de seguridad. La cuenta es personal, única e intransferible y está prohibido que
        un mismo usuario inscriba o posea más de una cuenta. En caso de que “TERRAMAR” detecte datos
        coincidentes, relacionados, apócrifos o alterados podrá cancelar, suspender o inhabilitar la
        cuenta o cuentas sin derecho a resarcimiento. El usuario es el único responsable de cada una
        de las operaciones efectuadas en su Cuenta, pues el acceso a la misma está restringido al
        ingreso y uso de su clave de Seguridad, de conocimiento exclusivo del usuario. El Usuario se
        compromete a notificar personalmente a “TERRAMAR” en forma inmediata y por el medio idóneo y
        fehaciente cualquier uso no autorizado de su cuenta, así como el ingreso por terceros. Se
        aclara que está prohibida la transferencia, venta, cesión, transferencia de la cuenta.
      </p>

      <p> 3.-­‐ PROHIBICIONES El usuario se compromete a no:</p>

      <p>
        a) Usar el Sitio para un fin distinto al que determine “TERRAMAR” <br />
        b) Reproducir, duplicar, copiar, vender, modificar, adaptar, descompilar, explotar,
        vincular, con un propósito distinto al aprobado por “TERRAMAR” cualquier parte del SITIO.{' '}
        <br />
        c) Usar derechos autor, marcas registradas o materiales informativos, comerciales o
        cualquier información no pública de “TERRAMAR”, sus empleados, socios, directivos,
        proveedores, sin la autorización por escrito de “TERRAMAR”. <br />
        d) Usar el sitio para la comisión de propósitos ilegales. <br />
        e) Proporcionar datos de otros usuarios. <br />
        f) Usar el Sitio violando el código de Ética. <br />
        g) Hacerse pasar por otra persona, suplantar identidad, incluyendo mas no limitando a
        cualquier empleado o representante de “TERRAMAR”. <br />
        h) Realizar conductas que pudieran dar lugar a acciones legales en contra de “TERRAMAR”.{' '}
        <br />
        i) Alentar o realizar actividades como “hacking”, “craking” o “pheaking”. <br />
        j) Introducir cualquier programa o archivo que sea dañino o invasivo, pueda dañar, piratear,
        desactivar, interferir, con la operación del Sitio. <br />
        k) Modificar, alterar, suprimir, en forma total o parcial los avisos, marcas, nombres
        comerciales, señas, logotipos, imágenes presentadas en el Sitio. l) La reimpresión,
        republicación, distribución, asignación, sublicencia, venta, venta, reproducción electrónica
        o por cualquier otro medio, documento o gráfico que aparezca en el Sitio en todo o en parte,
        para cualquier uso distinto al autorizado por “TERRAMAR”. <br />
        m) La impresión de cualquier parte de información del Sitio para fines personales o en
        perjuicio de “TERRAMAR”
      </p>

      <p>
        {' '}
        En caso de que “TERRAMAR” sospeche que haya cometido alguna de las prohibiciones o actividad
        ilícita, se reserva el derecho de adoptar todas las medidas necesarias, lo que puede incluir
        limitar el acceso o ejercer acciones legales.
      </p>

      <p>
        4.-­‐ RECOMENDACIONES Y PRECAUCIONES <br />
        a) No escriba su usuario y contraseña en lugares de fácil acceso. <br />
        b) Desconectar siempre la sesión después de haber introducido al sistema su usuario y
        contraseña. <br />
        c) No proporcionar información inusual sin verificar la solicitud con “TERRAMAR”. <br />
      </p>

      <p>
        {' '}
        5.-­‐ MODIFICACIONES AL ACUERDO. Cualquier modificación a los presentes términos y
        condiciones será realizada cuando “TERRAMAR” lo considere apropiada sin requerir la voluntad
        de los usuarios, siendo exclusiva responsabilidad del usuario asegurarse de tomar
        conocimiento de tales modificaciones. En caso de que derivado de las modificaciones el
        usuario no acepte las mismas deberá comunicarlo en los primeros 5 días hábiles, forma idónea
        y fehaciente a “TERRAMAR” y entonces quedará inhabilitado como usuario siempre y cuando no
        se tenga adeudos. De no hacerlo así se entiende que acepta los nuevos términos y
        condiciones.
      </p>

      <p>
        6.-­‐ POLÍTICA DE PRIVACIDAD SOBRE EL USO DE LA INFORMACIÓN A través del Sitio se puede
        obtener diversa información, y toda la información que se obtenga en el Sitio será tratada
        como CONFIDENCIAL, además de ser protegida contra alteración o uso distintos a los
        necesarios para la relación comercial entre el usuario y “TERRAMAR”. El usuario reconoce y
        acepta que “TERRAMAR” no obtiene la información personal automáticamente, sin que es el
        propio usuario quien la otorga de manera directa por lo que su decisión otorgarla o no. En
        el caso de que el usuario decida enviar información personal a través de cualquiera de los
        medios dispuestos, el usuario, para efectos de lo dispuesto por el artículo 76 bis de la Ley
        Federal de Protección al Consumir, otorga su consentimiento expreso de manera electrónica,
        para que “TERRAMAR” comparta su información con terceras personas.
      </p>

      <p>
        “TERRAMAR” respeta la privacidad de cada usuario por lo que podrá solicitar a “TERRAMAR” el
        acceso, retiro, modificación, actualización o supresión de su información personal.
      </p>

      <p>
        {' '}
        Para efectos de lo dispuesto en los artículos 16, 18 y 18 bis de la Ley Federal de
        Protección al Consumidor, el usuario otorga su consentimiento expreso y aceptación para
        recibir información con carácter o fines mercadológicos, estadísticos o publicitarios.
      </p>

      <p>
        {' '}
        El usuario faculta a “TERRAMAR” de la forma más amplia que en derecho proceda para utilizar
        su información personal para ceder su información a terceros, pudiendo compartir con su
        Líder. Cuando sea necesario compartir su información con agentes o proveedores que prestan
        servicios para “TERRAMAR” estará limitado a:
      </p>

      <p>
        a) Resolver controversias o disputas relacionadas con “TERRAMAR”; <br />
        b) Facilitar la operación de “TERRAMAR <br />
        c) En los casos previstos por la ley y el Reglamento. <br />
        d) Cuestiones estadísticas <br />
        e) Entrega de mercancía adquirida por el usuario. <br />
        f) Proporcionar sus datos a aquellos proveedores, socios, aliados estratégicos que Usted
        haya manifestado su interés o algún tipo de consentimiento.
      </p>

      <p>
        7.-­‐ MEDIDAS DE SEGURIDAD Ninguna transferencia de información a través de Internet será
        ciento por ciento segura. Si Bien en “TERRAMAR” tomamos todas las medidas que están a
        nuestro alcance para ´proteger sus datos personales, no podemos garantizar totalmente la
        seguridad de la información que el usuario transmite, por lo que la transmisión se efectúa
        bajo su propio riesgo. Al momento de recibir la transmisión hacemos todos los esfuerzos
        necesarios para tener seguridad en nuestros sistemas, sin embargo el usuario es el
        responsable de mantener en secreto su usuario, contraseña y cualquier otra información de su
        cuenta personal.
      </p>

      <p>
        8.-­‐ PROPIEDAD INTELECTUAL El Sitio es propiedad de “TERRAMAR” y operado por esta. Los
        contenidos, información, textos, gráficos, sonidos, videos, animaciones, etc. están
        protegidos en términos de la legislación vigente, por lo que no puede ser copiado,
        reproducido, distribuido, comercializado, modificado. También puede contener información
        cuya propiedad intelectual es de terceros, teniendo las mismas restricciones, en caso de
        usarla sin autorización por escrito de “TERRAMAR” pudiera constituir violaciones a los
        derechos de propiedad intelectual.
      </p>

      <p>
        Se deja constancia que toda ñola información o recomendación proporcionada por el Sitio
        tiene la función de orientar y asistir a quien lo visita y es brindada de buena fe por
        “TERRAMAR” por tal motivo no se responsabiliza por cualquier decisión o medida que pudiese
        adoptar basándose en la información contenida en el Sitio, ni por los perjuicios que deriven
        de ella. La utilización de dicha información es exclusiva del usuario. El Sitio contiene
        varias marcas registradas o en trámite de registro o de las cuales “TERRAMAR” es propietaria
        y/o licenciataria autorizada en México y posiblemente en otros países del mundo. TERRAMAR
        BRANDS y las otras maracas y nombres comerciales, marcas de servicios y diseños son de uso
        exclusivo de “TERRAMAR”. El uso de cualquiera de las marcas registradas o cualquier otro
        contenido del sitio, excepto autorización expresa está prohibido. De ninguna forma podrá
        considerarse que por el simple uso del Sitio o tener una relación mercantil con “TERRAMAR”
        podrá interpretarse como el otorgamiento de una licencia de uso expresa o tácita de dichas
        marcas. Todos los materiales contenidos en contenidos en este sitio son obras protegidas por
        la ley de la materia aplicable al momento de su visita y por lo tanto, el usuario se obliga
        a respetar los términos y condiciones establecidos en este sitio, así como lo señalado en
        las leyes y reglamentos aplicables. “TERRAMAR” se reserva el derecho de ejercitar las
        acciones legales que considere oportunas derivadas de cualesquiera usos ilícitos o
        infractores por parte de los usuarios o terceros.
      </p>

      <p>
        9.-­‐ NOMBRES DE DOMINIOS El usuario no deberá hacer mal uso de los mismos y7o registrar
        nombres de dominio similares que puedan causar confusión con el público en general, tanto en
        México como en cualquier parte del mundo. Los contenidos de las pantallas relativas a los
        servicios de “TERRAMAR” como los programas, bases de datos, redes, archivos que permiten al
        usuario acceder y usar su cuenta, son propiedad de “TERRAMAR” y están protegidas por las
        leyes y tratados internacionales de derechos de autor, marcas, patentes, modelos y diseños
        industriales. El uso indebido y reproducción total o parcial de dichos contenidos quedan
        prohibidos salvo autorización expresa y por escrita de algún representante legal de
        “TERRAMAR”.
      </p>

      <p>
        {' '}
        10.-­‐ VIOLACIONES AL SISTEMA O BASES DE DATOS No está permitida ninguna acción o uso de
        dispositivo, software u otro medio tendiente a interferir tanto como en las actividades y
        operación de “TERRAMAR” como en las ofertas, descripciones, cuentas o bases de datos de
        “TERRAMAR”. Cualquier intromisión, tentativa o actividad violatoria o contraria a las leyes
        sobre derecho de propiedad intelectual y/o a las prohibiciones estipuladas en este contrato
        harán posible que “TERRAMAR” ejerza las acciones legales adicionalmente a la obligación del
        usuario de indemnizar a “TERRAMAR” por los daños causados.
      </p>

      <p>
        {' '}
        11.-­‐ SUSPENSIÓN DE OPERACIONES Sin perjuicio de otras medidas “TERRAMAR” advertirá,
        suspenderá temporal o inhabilitará definitivamente la cuenta del usuario e iniciará las
        acciones que estime pertinente y no le prestara sus servicios si quebranta alguna ley o
        quebranta cualquiera de los supuestos previstos en los términos y condiciones generales y
        demás políticas de “TERRAMAR” o si incurre a criterio de “TERRAMAR” en conductas pocos
        éticas, o actos dolosos o ilícitos.
      </p>

      <p>
        {' '}
        12.-­‐ ALCANCE DE LA INFORMACIÓN El Sitio se brinda “como está”, sin garantías de ningún
        tipo, ya asean expresa o implícitas, incluyendo pero sin carácter taxativo, garantías
        implícitas de título, comerciabilidad y adecuación para cualquier propósito particular. Todo
        uso del material del sitio será a su exclusivo riesgo. El usuario es el único responsable de
        la evaluación y compra de los productos y servicios ofrecidos en el Sitio a través de los
        medios de compra disponibles. “TERRAMAR “ no será responsable de ninguna decisión que el
        usuario tome luego de su vista en el Sitio. La información contenida en el sitio no implica
        ningún tipo de opinión sobre la seguridad o calidad de los distintos tipos de servicios y/o
        productos ofrecidos por el mismo. Las opiniones, análisis y/o información contenidas en el
        Sitio, serán provistas a los usuarios con el único fin de colaborar con los mismos a tomar
        sus propias decisiones, por lo que tampoco podrá entenderse como una relación de
        supra-­‐subordinación entre el usuario y “TERRAMAR”.
      </p>

      <p>
        13.-­‐ ACCESABILIDAD “TERRAMAR” no garantiza que el Sitio se encuentre disponible de manera
        continua e interrumpida o sin errores, sin enlaces defectuosos, inexactitudes o errores
        tipográficos, ni tampoco garantiza que los defectos serán corregidos o que el Sitio o los
        servidores estén libres de virus u otros componentes perjudiciales. Por los que de ninguna
        manera podrá resarcir cualquier daño generado por lo señalado en el presente párrafo.
      </p>

      <p>
        14.-­‐ RESPONSABILIDAD “TERRAMAR” sólo pone a disposición de los usuarios un espacio virtual
        que les permite ponerse en comunicación mediante Internet. “TERRAMAR” no asume
        responsabilidad alguna por cualquier actividad o conducta de los usuarios, ni respecto de
        las opresiones mercantiles derivadas del uso del sitio. El uso que el usuario haga del Sitio
        no podrá ser interpretado como un contrato de mandato, agencia o sociedad o que de algún
        modo genere algún tipo de relación entre el usuario y “TERRAMAR”. En ninguna circunstancia
        “TERRAMAR” será el responsable por cualquier daño (incluyendo sin limitación, daño o
        perjuicio, lucro cesante o daños resultantes de la pérdida de datos o interrupción de
        negocios) resultantes del acceso a, o la imposibilidad de accesar al contenido del Sitio y/o
        cualquier documento o información. “TERRAMAR” en ningún caso será responsable por daños
        incidentales, especiales o consecuenciales que sugieran del uso o la imposibilidad de uso
        del Sitio, aun cuando “TERRAMAR” o un representante hayan sido notificados de la posibilidad
        de dichos daños.
      </p>

      <p>
        En caso de que uno o más usuarios o algún tercero inicien cualquier tipo de reclamo o
        acciones legales contra otro u otros usuarios. El usuario individualmente o en conjunto
        exime de toda responsabilidad a “TERRAMAR” a sus directores, socios, gerentes, empleados,
        agentes, operarios, representantes y apoderados.
      </p>

      <p>
        15.-­‐ FALLAS EN EL SISTEMA “TERRAMAR” no se responsabiliza por cualquier daño, perjuicio o
        pérdida al usuario causado por fallas en el sistema, en el servidor o en Internet.
        “TERRAMAR” no será responsable por cualquier virus que pudiera afectar el equipo del usuario
        como consecuencias del acceso, examen o uso del sitio o a raíz de cualquier transferencia de
        datos, archivos, imágenes, textos o audio contenidos en el mismo. Los usuarios no podrán
        imputarle responsabilidad alguna ni exigir pago por lucro cesante, en virtud de perjuicio s
        resultantes de dificultades técnicas o fallas en el sistema o internet.
      </p>

      <p>
        16.-­‐ ENLACES El Sitio puede contener enlaces a otros sitios web lo que no implica que sean
        propiedad u operados por “TERRAMAR”. En virtud de que “TERRAMAR” no tiene control sobre
        tales sitios, no será responsable por contenidos, ,materiales, acciones, y/o servicios
        prestados por los mismos, ni por daños o pérdidas ocasionadas por la utilización de los
        mismos, sean causadas directa o indirectamente. La presencia de enlaces a otros sitios web
        no implica que haya una sociedad, relación, aprobación, respaldo de “TERRAMAR” a dichos
        sitios y contenidos.
      </p>

      <p>
        {' '}
        17.-­‐ INDEMINIZACIÓN El usuario indemnizará y mantendrá indemne a “TERRAMAR”, sus filiales,
        empresas controladoras o controlantes, directivos, administradores, empleados, y
        representantes, por cualquier reclamo o demanda de otros usuarios o terceros por sus
        actividades en el Sitio o por su incumplimiento los Términos y Condiciones Generales y demás
        Políticas que se entienden incorporadas al presente o por la violación de cualesquiera leyes
        o derechos de terceros, incluyendo los honorarios de abogados. Usted indemnizará y dejará en
        paz y salvo a “TERRAMAR” ”, sus filiales, empresas controladoras o controlantes, directivos,
        administradores, empleados, y representantes, por cualquier reclamo o demanda de otros
        usuarios o terceros debido a, o que sugieran la falta de cumplimiento de los términos y
        Condiciones o violación a los derechos de “TERRAMAR”.
      </p>

      <p>
        18.-­‐ANEXOS Forman parte integral e inseparable de los términos y condiciones generales,
        los siguientes documentos en donde se detallan Políticas y/o términos y condiciones,
        promociones o cualquier otro de los servicios ofrecidos en el Sitio. Los mismos se podrán
        consultar dentro del Sitio.
      </p>

      <p>
        19.-­‐ JURISDICCIÓN Y LEY APLICABLE Este acuerdo está regido por las leyes vigentes en la
        República Mexicana, en particular respecto de mensajes de datos, contratación electrónica y
        comercio electrónico se regirá por lo dispuesto por la legislación federal respectiva.
        Cualquier controversia derivada del presente acuerdo serán sometidos a las leyes de México,
        Distrito Federal, renunciando el usuario al fuero que pudiera corresponderle y por tanto se
        somete a los tribunales del Distrito Federal. Si cualquiera de las disposiciones de hacer
        estos términos fueses no aplicable, ilegítima, nula o por cualquier razón imposible de hacer
        o cumplir, entonces dicha disposición se considerará nula con respecto a estos términos y no
        afectará la validez y vigencia de las restantes disposiciones.
      </p>

      <p>
        20.-­‐ DOMICILIO Se fija como domicilio de “TERRAMAR” la calle de Norte 45 número 1017 Local
        11, Colonia Industrial Vallejo, México, DF, Delegación Azcapotzalco, CP 02300. Si tienes
        alguna duda sobre los términos y Condiciones Generales y/o demás políticas y principios que
        rigen en “TERRAMAR” consulta nuestra página de ayuda. ACEPTACIÓN DE ESTOS TÉRMINOS Y
        CONDICIONES
      </p>

      <p>
        {' '}
        Por el solo hecho de utilizar o acceder al Sitio, usted acepta estos Términos y Condiciones
        Generales de Uso. Si no las acepta, le rogamos que no utilice o acceda al sitio, “TERRAMAR”
        se reserva el derecho de modificar en cualquier momento los Términos y Condiciones Generales
        de Uso. El acceso o uso continuado que usted efectué del Sitio con posterioridad a los
        cambios introducidos implicará su total aceptación a dichas modificaciones, por lo que le
        recomendamos relea estos Términos y Condiciones Generales de Uso, cada vez que ingrese al
        Sitio.
      </p>

      <p>
        <strong>POLITICAS DE PRIVACIDAD Y CONFIDENCIALIDAD DE LA INFORMACIÓN</strong>{' '}
      </p>

      <p>
        {' '}
        Con fundamento en los artículos 15 y 16 de la Ley de Protección de Datos Personales en
        Posesión de los Particulares, hacemos de su conocimiento las políticas de privacidad
        (“Políticas de Privacidad”) de TERRAMAR BRANDS, S. DE R.L. DE C.V. (“TERRAMAR”) mismas que
        entraran en vigor desde su aceptación. Como parte normal de nuestra actividad recogemos y,
        en algunos casos revelamos información sobre nuestros usuarios y visitantes del Sitio Web.
        Estas Políticas de Privacidad describen la información que “TERRAMAR” recoge sobre sus
        usuarios y visitantes y lo que puede hacerse con dicha información. Estas políticas son muy
        detalladas porque es muy importante que todos los usuarios y visitantes de nuestro Sitio
        conozcan las prácticas de “TERRAMAR” de la forma más precisa posible. Al inscribirse o
        navegar en el Sitio de “TERRAMAR” el usuario y/o visitante presta su consentimiento para que
        se utilice su información personal de acuerdo con las políticas que se describen a
        continuación:
      </p>

      <p>
        La privacidad de la información es muy importante para “TERRAMAR”, es por ello que se toman
        las precauciones y recaudos para resguardar su información, utilizando mecanismos de
        seguridad informática de protección de la información. Este documento es parte integrante de
        los Términos y Condiciones Generales de “TERRAMAR”.
      </p>

      <p>
        {' '}
        Mediante la aceptación de os Términos y Condiciones Generales en el momento de inscripción
        y/o uso, el usuario acepta las políticas aquí contenidas. “TERRAMAR” tiene su domicilio en
        la calle de Norte 45 número 1017 Local 11, Colonia Industrial Vallejo, México, DF,
        Delegación Azcapotzalco, CP 02300.
      </p>

      <p>
        {' '}
        La información que recabamos: Lo primero que se debe hacer para disfrutar de los servicios
        del Sitio de “TERRAMAR”, es inscribirse suministrando ciertos datos personales (“Información
        Personal”) completos y exactos. Podremos solicitar, recabar y almacenar la siguiente
        Información Personal: nombre, número de documento o identificación válida, información
        física de contacto como número de teléfono, domicilio, dirección de e mail, oct. “TERRAMAR”
        podrá informar los datos personales suministrados acudiendo a entidades públicas,
        proveedores, compañías especializadas o centrales de riesgo, para lo cual nos autorizas
        expresamente. La información que “TERRAMAR” obtenga de esas entidades será tratada como
        confidencial.
      </p>

      <p>
        {' '}
        Los datos que recabamos podrán ser solicitados en presencia del titular, vía web, vía chat,
        vía e mail, por encuestas personales o en línea, telefónicamente, etc. y podrán consistir en
        nombre completo, domicilio, estado civil, teléfono fijo o móvil, id de facebook, twitter y/o
        linkedin, RFC, CURP; tarjeta de crédito o débito, institución bancaria, edad, sexo, correo
        electrónico, , firma autógrafa, número de KIT, Fecha de Ingreso o Grupo
      </p>

      <p>
        {' '}
        Se acuerda expresamente que en cualquier momento el usuario inscrito en “TERRAMAR” podrá
        solicitar la baja o cierre de su solicitud de inscripción y/o cierre de su cuenta o
        eliminación de su cuenta e información en base a los datos de “TERRAMAR”. Por favor revisa
        la sección “Derechos de acceso, cancelación y rectificación de la información personal” que
        a continuación se presenta: “TERRAMAR” recoge y almacena automáticamente cierta información
        de datos que proporciona voluntariamente, actividad de los usuarios y visitantes en el
        Sitio, Tal información puede incluir la URL de la que provienen (estén o no en nuestro
        sitio), a que URL acceden seguidamente, que navegador están usando y sus direcciones IP.
        También las páginas visitadas, las búsquedas realizadas, las publicaciones, compras o
        ventas, calificaciones, strickes, réplicas, mensajes en otros foros, imágenes, entre otra
        información podrá será almacenada o retenida.
      </p>

      <p>
        {' '}
        Si el usuario envía correspondencia sean correos electrónicos o caratas, o si otros usuarios
        o terceros nos envían correspondencia sobre las actividades o sobre los mensajes de otros
        usuarios de manera directa o por el Sitio, podemos recoger y almacenar tal información
        personal, incluyendo direcciones de e mail, domicilio o teléfonos.
      </p>
      <p>
        {' '}
        Se notifica a todos los usuarios que en términos de la Ley Federal de Protección de Datos
        Personales en Posesión de los Particulares, establece que el Aviso de Privacidad debe
        incluir la información de la persona o departamento al interior de la empresa para que el
        titular pueda ejercer sus derechos ARCO. (Acceso, Rectificación, Cancelación y Oposición).
        Recuerde el derecho ARCO puede ejercerse enviando una solicitud en los términos que marca la
        Ley en su artículo 29, enviando notificación personal al Gerente de Sistemas, responsable de
        nuestro departamento de Protección de Datos Personales, ubicado en calle de Norte 45 número
        1017 Local 11, Colonia Industrial Vallejo, México, DF, Delegación Azcapotzalco, CP 02300, o
        enviando un correo electrónico a soporte@terramarbrands.com el cual requerirá ser confirmado
        vía telefónica para garantizar su correcta recepción en el teléfono 50781600
      </p>

      <p>USO QUE “TERRAMAR” HACE DE LA INFORMACIÓN</p>

      <p>
        Para suministrar el servicio “TERRAMAR” requiere cierta información de carácter personal,
        incluyendo la dirección de e mail. La recolección de esta información nos permite a los
        usuarios mantener contacto y ofrecer a los usuarios servicios y funcionalidades que se
        adecuen mejor a sus necesidades, personalizar los servicios y realizar mejoras. La
        información que recabamos tiene las siguientes finalidades: <br />
        1) Fines Mercadológicos, para mejorara nuestras iniciativas comerciales, promocionales y
        analizar las páginas visitadas, las búsquedas realizadas, para mejorar la oferta de
        contenidos y artículos, personalizar los mismos, su presentación servicios y productos.{' '}
        <br />
        2) Desarrollar estudios internos sobre los intereses, comportamientos, y demografía de los
        usuarios para comprender mejor sus necesidades e intereses y ofrecer mejores servicio y
        productos basados en ello, o en su caso brindarles información relacionada. <br />
        3) Enviar información o mensajes por e mail sobre nuevos productos o servicios,
        recomendaciones para su negocio, tips, mostrar publicidad o promociones, banners de
        intereses para el usuarios, noticias sobre “TERRAMAR”, además de la información. Si el
        usuario lo prefiere puede solicitar que lo excluyen de las listas de envió de información
        promocional o publicitaria. <br />
        4) Compartir información personal con las empresas de outsoursing o proveedores que
        contribuyan facilitar o mejorar las operaciones de “TERRAMAR” o sus operaciones como
        comerciante independiente de manera enunciativa mas no limitativa: medios de transferencias
        electrónicas, servicios de transporte, call center, servicios de cobranza, seguros,
        intermediarios en la gestión de pagos, programas de fidelidad, entre otros. “TERRAMAR”
        procurará que se velen ciertos estándares como convenios de confidencialidad o privacidad de
        los datos personales, sin embargo no podrá responsabilizarse por el uso indebido de la
        información personal que hagan estos terceros o del Sitio. En algunos casos los terceros
        antes mencionados podrán recoger directamente la información personal directamente del
        usuario, quedando a discreción de los usuarios toda la información que quiera brindarle y
        los usos adicionales. En caso de facilitar o proporcionar por propia iniciativa información
        adicional a dichos prestadores de servicios, tales prestadores usaran esta información
        conforme a sus propias políticas de privacidad. <br />
        5) Los ganadores de promociones, sorteos, concursos o cualquier otro similar realizados por
        “TERRAMAR” o por terceros autorizan expresamente a difundir sus nombres, imágenes, datos
        personales de ellos y sus familias, por los medios y en las formas que se consideren
        convenientes, con fines publicitarios y/o promocionales, sin derecho a compensación alguna o
        retribución de alguna forma.
      </p>

      <p>
        {' '}
        En caso de no obtener su oposición expresa para que sus datos personales sean transferidos
        en la forma y términos antes descrita, entenderemos a ha otorgado su consentimiento en forma
        tácita para ello. En caso de que no desee recibir información promocional puede enviar la
        solicitud a la siguiente dirección electrónica: soporte@terramarbrands.com
      </p>

      <p>CONFIDENCIALIDAD DE LA INFORMACION</p>

      <p>
        {' '}
        Una vez inscrito en el sitio, “TERRAMAR” no venderá, alquilará o compartirá la información
        personal excepto en las formas establecidas en estas políticas. Sin perjuicio de ello, el
        usuario consiente en forma expresa que “TERRAMAR” transfiera totalmente o parcialmente la
        información personal a cualquiera de las sociedades controlantes o controladoras y /o
        cualquiera vinculada con “TERRAMAR” a cualquier título y en el momento, forma y condiciones
        qué estime pertinente. Haciendo lo posible por proteger la privacidad de la información.
        Pudiendo suceder que por mandatos judiciales, o normatividad nos ve amos obligados a revelar
        información a las autoridades o terceros ajo ciertas circunstancias, o bien en casos en que
        terceras personales puedan interceptar o acceder a cierta información o transmisiones de
        dato, en cuyo caso “TERRAMAR” no responderá por la información que sea revelada.
      </p>

      <p>
        {' '}
        USUARIO Para interactuar y usar el Sitio, los usuarios deben utilizar un USUARIO Y LOGIN que
        los identificará. Los usuarios no tendrán acceso a la información personal de los otros
        usuarios, cuando hayan realizado con ellos alguna operación a través de nuestra plataforma,
        luego de la cual ambas partes recibirán información de la otra. LOGIN-­‐ CLAVE PERSONAL
      </p>
      <p>
        {' '}
        Para acceder a los servicios reservados únicamente para los usuarios inscritos, los usuarios
        dispondrán de una clave personal login. LA CUAL DEBE MANTENERLA SOBRE SU ABSOLUTA
        CONFIDENCIALIDAD y en ningún caso deberá revelarla, compartirla, o dejarla al alcance de
        terceras personas, ya que el usuario es el responsable de los actos que tengan lugar
        mediante el uso de su USUARIO Y LOGIN, lo que incluye sin limitar: hacerse cargo de los
        pagos adquiridos por este medio y por cualquier perjuicio que puedan sufrir otros usuarios
        por tal motivo. Si por cualquier razón un usuario sospecha que alguien puede conocer su
        clave debe cambiarla de inmediato y hacerlos del conocimiento de “TERRAMAR”.
      </p>
      <p>COOKIES</p>

      <p>
        {' '}
        El usuario o visitante del Sitio conocen y aceptan que “TERRAMAR” podrá utilizar un sistema
        de seguimiento mediante cookies que son pequeños archivos que se instalan en el disco
        rígido, con una duración limitada en el tiempo que ayudan a personalizar los servicios.
        También las cookies se utilizan con el fin de conocer los intereses, comportamiento,
        demografía de quienes visitan o son usuarios de nuestro Sitio: también se usará la
        información obtenida mediante cookies para analizar las páginas navegadas por el usuario,
        las búsquedas realizadas, mejorara iniciativas comerciales y promocionales, mostrar
        publicidad, banners de interés, noticias sobre “TERRAMAR”, perfeccionar nuestra oferta,
        personalizar contenidos, presentaciones, servicios o productos. “TERRAMAR” podrá agregar las
        cookies en los e mails que envíe para medir la efectividad de las promociones.
      </p>

      <p>
        {' '}
        Se podrán utilizar también para que el usuario no tenga que introducir su clave tan
        frecuentemente durante una sesión de navegación, también para contabilizar y corroborar las
        inscripciones, la actividad del usuario y los acuerdos comerciales. Se establece que la
        instalación, permanencia y existencia de cookies u otros sistemas similares a las cookies en
        el computador del usuario depende de su exclusiva voluntad y puede ser eliminada de su
        computador, aclarando que “TERRAMAR” no controla las cookies utilizadas por terceros.
      </p>

      <p>WEB BEACONS</p>

      <p>
        {' '}
        Un web beacon es una imagen electrónica también llamada single-­‐pixel que es colocada en
        código de una página web y es utilizado para medir patrones de tráfico y con finalidades
        similares a las cookies, por lo que el usuario autoriza expresamente que “TERRAMAR” utilice
        cualquier sistema de seguimiento mediante la utilización de web beacons.
      </p>

      <p>REQUERIMENTOS LEGALES</p>

      <p>
        {' '}
        “TERRAMAR” coopera con autoridades competentes y otros terceros para garantizar el
        cumplimiento a las leyes, por lo que podrá revelar información personal de sus usuarios bajo
        requerimiento de las autoridades judiciales o administrativas competentes, aunque no exista
        una orden ni un citatorio. En tales situaciones “TERRAMAR” colaborará con las autoridades a
        fin de salvaguardar la integridad y seguridad de los usuarios y la sociedad. Los usuarios
        autorizan expresamente a “TERRAMAR” a comunicar cualquier información personal sobre ellos
        para cumplir con disposiciones legales y cooperar con las autoridades competentes, así como
        comunicar su nombre completo, seudónimo, domicilio, ciudad, religión, código postal, país,
        número telefónico, fijo o móvil, correo electrónico, IP, y cualquier otro dato.
      </p>
      <p>
        {' '}
        El usuario autoriza expresamente a “TERRAMAR” a reservarse el derecho de comunicar
        información sobre los usuarios a otros usuarios cuando haya motivos suficientes a
        consideración de “TERRAMAR” para considerar que de ello depende el perjuicio a la integridad
        y seguridad.
      </p>

      <p> ALMACENAMIENTO DE INFORMACIÓN</p>
      <p>
        {' '}
        “TERRAMAR” está obligado a cumplir con la normatividad relativa a la Información Personal y
        considera a los datos de los usuarios como un activo que debe ser protegido de cualquier
        pérdida o acceso no autorizado, por lo que empleamos técnicas de seguridad en la medida de
        lo posible.
      </p>

      <p>
        {' '}
        Por lo antes expuesto, “TERRAMAR” no se hace responsable por interceptaciones ilegales o
        violación de sus sistemas o bases de datos por parte de personas no autorizadas, ni por la
        indebida utilización de la información obtenida por estos medios.
      </p>

      <p>
        {' '}
        Todos los datos personales de los usuarios serán almacenados en fichero automatizado de
        datos personales, que reside en México, D.F. el usuario al inscribirse y acceder al Sitio
        confirma que está informado de la residencia de este fichero y autoriza la transferencia
        internacional de datos.
      </p>

      <p>
        {' '}
        Importante cualquier aviso o modificación a este AVISO DE PRIVACIDAD podrá consultarlo en
        http://www.terramarbrands.com Fecha de última actualización: 23/08/2013
      </p>
    </div>
  );
};

export default TermsOfUse;
