import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.sass';

//Commponents
import HeroBanner from '../HeroBanner';

const Catalog = (props) => {
  const { device = {} } = props;
  const { screenSize = 'desktop' } = device;

  const { t } = useTranslation('catalog');

  return (
    <div id={styles.Catalog} className={styles[screenSize]}>
      <HeroBanner device={device} desktopImg={t('bannerDesktop')} mobileImg={t('bannerMobile')} />
      <div className={styles.documentsContainer}>
        <div className={styles.document}>
          <img src={t('documents.catalog.image')} alt={t('documents.catalog.name')} />
          <a
            href={t('documents.catalog.pdfLink')}
            target='_blank'
            rel='noopener noreferrer'
            className={styles.button}>
            {t('documents.catalog.name')}
          </a>
        </div>
        <div className={styles.document}>
          <img src={t('documents.booklet.image')} alt={t('documents.booklet.name')} />
          <a
            href={t('documents.booklet.pdfLink')}
            target='_blank'
            rel='noopener noreferrer'
            className={styles.button}>
            {t('documents.booklet.name')}
          </a>
        </div>
        <div className={styles.document}>
          <img src={t('documents.magazine.image')} alt={t('documents.magazine.name')} />
          <a
            href={t('documents.magazine.pdfLink')}
            target='_blank'
            rel='noopener noreferrer'
            className={styles.button}>
            {t('documents.magazine.name')}
          </a>
        </div>
        <div className={styles.document}>
          <img src={t('documents.request.image')} alt={t('documents.request.name')} />
          <a
            href={t('documents.request.pdfLink')}
            target='_blank'
            rel='noopener noreferrer'
            className={styles.button}>
            {t('documents.request.name')}
          </a>
        </div>

        <div className={styles.document}>
          <img src={t('documents.sgipping.image')} alt={t('documents.sgipping.name')} />
          <a
            href={t('documents.sgipping.pdfLink')}
            target='_blank'
            rel='noopener noreferrer'
            className={styles.button}>
            {t('documents.sgipping.name')}
          </a>
        </div>
        <div className={styles.document}>
          <img src={t('documents.bonus.image')} alt={t('documents.bonus.name')} />
          <a
            href={t('documents.bonus.pdfLink')}
            target='_blank'
            rel='noopener noreferrer'
            className={styles.button}>
            {t('documents.bonus.name')}
          </a>
        </div>
        <div className={styles.document}>
          <img src={t('documents.car.image')} alt={t('documents.car.name')} />
          <a
            href={t('documents.car.pdfLink')}
            target='_blank'
            rel='noopener noreferrer'
            className={styles.button}>
            {t('documents.car.name')}
          </a>
        </div>
        <div className={styles.document}>
          <img src={t('documents.howToPay.image')} alt={t('documents.howToPay.name')} />
          <a
            href={t('documents.howToPay.pdfLink')}
            target='_blank'
            rel='noopener noreferrer'
            className={styles.button}>
            {t('documents.howToPay.name')}
          </a>
        </div>
        {/* <div className={styles.document}>
          <img
            src={t('documents.consultingManual.image')}
            alt={t('documents.consultingManual.name')}
          />
          <a
            href={t('documents.consultingManual.pdfLink')}
            target='_blank'
            rel='noopener noreferrer'
            className={styles.button}>
            {t('documents.consultingManual.name')}
          </a>
        </div> */}
        <div className={styles.document}>
          <img src={t('documents.qualification.image')} alt={t('documents.qualification.name')} />
          <a
            href={t('documents.qualification.pdfLink')}
            target='_blank'
            rel='noopener noreferrer'
            className={styles.button}>
            {t('documents.qualification.name')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Catalog;
