import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';

//  kkGkg9

// Components
import SectionTitle from '../SectionTitle';
import TextInput from '../TextInputTMB';
import Button from '@material-ui/core/Button';

// Queries
import getGlobal from '../Queries/getGlobal';
import postGlobal from '../Queries/postGlobal';
import mxStates from '../Queries/mxStates';

// Hooks
import useInput from '../../hooks/useInput';
import validateData from '../../hooks/validateData';

// Styles
import styles from './index.module.sass';

export default function IpaperOrder({ device }) {
  const { orderId = '' } = useParams();
  const screenSize = device?.screenSize || 'desktop';
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingOrder, setLoadingOrder] = useState(true);
  const [acceptedPolicies, setAcceptedPolicies] = useState(false);

  const nameInput = useInput({
    name: 'name',
    label: '',
    value: '',
    placeholder: `Nombre *`,
    errors: {
      requiredError: 'Ingresa tu nombre',
    },
    required: true,
    validateEvent: 'blur',
  });

  const lastnameInput = useInput({
    name: 'lastname',
    label: '',
    value: '',
    placeholder: `Apellido *`,
    errors: {
      requiredError: 'Ingresa tu apellido',
    },
    required: true,
    validateEvent: 'blur',
  });

  const phoneInput = useInput({
    name: 'phone',
    label: '',
    value: '',
    placeholder: `Teléfono *`,
    errors: {
      requiredError: 'Ingresa tu teléfono',
      minlengthError: 'Ingresa un teléfono de 10 dígitos',
    },
    required: true,
    validateEvent: 'blur',
    type: 'number',
    maxlength: 10,
    minlength: 10,
    validateMinLengthOnBlur: true,
  });

  const mailInput = useInput({
    name: 'mail',
    label: '',
    value: '',
    placeholder: `Mail *`,
    errors: {
      requiredError: 'Ingresa tu Mail',
    },
    required: true,
    validateEvent: 'blur',
    regexp: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
    type: 'email',
  });

  const addressInput = useInput({
    name: 'address',
    label: '',
    value: '',
    placeholder: `Dirección *`,
    errors: {
      requiredError: 'Ingresa tu dirección',
    },
    required: true,
    validateEvent: 'blur',
    maxlength: 50,
  });

  const cityInput = useInput({
    name: 'city',
    label: '',
    value: '',
    placeholder: `Ciudad *`,
    errors: {
      requiredError: 'Ingresa una ciudad',
    },
    required: true,
    validateEvent: 'blur',
  });

  const stateInput = useInput({
    name: 'state',
    label: '',
    value: '',
    placeholder: `Estado *`,
    errors: {
      requiredError: 'Ingrersa un estado',
    },
    required: true,
    validateEvent: 'blur',
  });

  const countryInput = useInput({
    name: 'country',
    label: '',
    value: 'MX',
    placeholder: `País *`,
    errors: {
      requiredError: 'Ingresa un País',
    },
    required: true,
    validateEvent: 'blur',
    disabled: true,
  });

  const postalcodeInput = useInput({
    name: 'postalcode',
    label: '',
    value: '',
    placeholder: `Código postal *`,
    errors: {
      requiredError: 'Ingresa un código postal',
    },
    required: true,
    validateEvent: 'blur',
    type: 'number',
    maxlength: 5,
  });

  const getOrder = useCallback(async () => {
    setLoadingOrder(true);
    const resp = await getGlobal({
      tmbservice: 'getOrden',
      jsonBody: {
        idPedido: orderId,
        idioma: window?.localStorage?.getItem('i18nextLng')?.includes('es') ? 0 : 1,
      },
    });
    const { typeMessage, pedido = {}, message } = resp || {};
    if (typeMessage === 'success') {
      setOrder(pedido);
    } else alert(message);
    setLoadingOrder(false);
  }, [orderId]);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    const { errors, data } = validateData([
      nameInput,
      lastnameInput,
      phoneInput,
      mailInput,
      addressInput,
      cityInput,
      stateInput,
      countryInput,
      postalcodeInput,
    ]);

    if (!errors) {
      if (!data?.mail.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
        alert('Ingresa un mail válido');
        return;
      }

      const resp = await postGlobal({
        tmbservice: 'postSolicitarPedido',
        jsonBody: {
          idPedido: orderId,
          nombre: `${data?.name} ${data?.lastname}`,
          telefono: data?.phone,
          correo: data?.mail,
          idioma: window?.localStorage?.getItem('i18nextLng')?.includes('es') ? 0 : 1,
          domicilio: {
            city: data?.city,
            country: data?.country,
            line1: data?.address,
            line2: '',
            line3: '',
            postalCode: data?.postalcode,
            region: data?.stateInput,
          },
        },
      });

      const { typeMessage = 'error', message } = resp;

      if (typeMessage === 'success') {
        alert('Tu orden ha sido enviada correctamente');
        window.location.href = 'https://www.terramarbrands.com.mx/';
      } else alert(message);
    } else {
      let errorsText = '';

      for (let i = 0; i < errors.length; i++) {
        errorsText += `- ${errors[i]?.input?.error}`;
      }
      alert(errorsText);
    }
    setLoading(false);
  }, [
    orderId,
    nameInput,
    lastnameInput,
    phoneInput,
    mailInput,
    addressInput,
    cityInput,
    stateInput,
    countryInput,
    postalcodeInput,
  ]);

  const buttonIsDisabled = useMemo(() => {
    return !(
      nameInput.input.value &&
      lastnameInput.input.value &&
      phoneInput.input.value &&
      mailInput.input.value &&
      addressInput.input.value &&
      cityInput.input.value &&
      stateInput.input.value &&
      countryInput.input.value &&
      postalcodeInput.input.value &&
      acceptedPolicies
    );
  }, [
    nameInput.input.value,
    lastnameInput.input.value,
    phoneInput.input.value,
    mailInput.input.value,
    addressInput.input.value,
    cityInput.input.value,
    stateInput.input.value,
    countryInput.input.value,
    postalcodeInput.input.value,
    acceptedPolicies,
  ]);

  useEffect(() => {
    getOrder();
  }, [getOrder]);

  return (
    <div className={`${styles.container} ${styles[screenSize]}`}>
      <div className={styles.orderContainer}>
        <SectionTitle title='SOLICITAR' subtitle='pedido' />
        {(loadingOrder && (
          <div className={styles.loadingOrder}>
            <CircularProgress className={styles.circularProgressOrder} />
          </div>
        )) || (
          <>
            <div className={styles.orderTable}>
              {screenSize !== 'phone' && (
                <div className={styles.row}>
                  <div className={styles.column}></div>
                  <div className={styles.column}>
                    <div className={styles.title}>Clave</div>
                  </div>
                  <div className={`${styles.column} ${styles.descri}`}>
                    <div className={styles.title}>Descripción</div>
                  </div>
                  <div className={styles.column}>
                    <div className={styles.title}>Cantidad</div>
                  </div>
                  <div className={styles.column}>
                    <div className={styles.title}>Precio</div>
                  </div>
                  <div className={styles.column}>
                    <div className={styles.title}>Importe</div>
                  </div>
                </div>
              )}

              {(order?.detallePedido || []).map((item, key) => {
                return (
                  <div className={`${styles.row} ${styles.rowItem}`} key={key}>
                    <div className={styles.column}>
                      <img
                        src={`https://webimages.terramarbrands.com.mx/shopping-cart/cart-products/${item?.codigo}.jpg`}
                        alt=''
                        className={styles.itemImage}
                      />
                    </div>
                    <div className={styles.column}>
                      <div className={styles.itemCode}>{item?.codigo}</div>
                    </div>

                    <div className={`${styles.column} ${styles.descri}`}>
                      <div className={styles.itemDesc}>{item?.descripcion}</div>
                    </div>
                    {screenSize !== 'phone' && (
                      <>
                        <div className={styles.column}>
                          <div className={styles.itemData}>{item?.cantidad}</div>
                        </div>
                        <div className={styles.column}>
                          <div className={styles.itemData}>${item?.precio}</div>
                        </div>
                        <div className={styles.column}>
                          <div className={styles.itemData}>${item?.precio * item?.cantidad}</div>
                        </div>
                      </>
                    )}
                    {screenSize === 'phone' && (
                      <div className={styles.quantityMobile}>
                        <div className={styles.itemData}>Cantidad: {item?.cantidad}</div>
                        <div className={styles.itemData}>Precio: ${item?.precio}</div>
                        <div className={styles.itemData}>
                          Importe: ${item?.precio * item?.cantidad}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>

            <div className={styles.resumeContainer}>
              <div className={styles.rightContainer}>
                <div className={styles.resumeTitle}>Resumen</div>
                <div className={`${styles.resumeTotal} ${styles.resumeAmount}`}>
                  <div className={styles.resumeText}>Total:</div>
                  <div className={styles.resumeNumber}>${order?.total}</div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className={styles.cardHeader}>
          <div className={styles.cardTitle}>
            <i className={`material-icons-outlined ${styles.icon}`}>local_shipping</i>
            Datos del pedido
          </div>
          <div className={styles.rightText}>* Campos obligatorios</div>
        </div>
        <div className={styles.cardContainer}>
          <div className={styles.dataTitle}>Datos personales</div>
          <div className={styles.inputRow}>
            <div
              className={`${
                (screenSize !== 'phone' && styles.container50) || styles.container100
              }`}>
              <TextInput input={nameInput.input} />
            </div>
            <div
              className={`${
                (screenSize !== 'phone' && styles.container50) || styles.container100
              }`}>
              <TextInput input={lastnameInput.input} />
            </div>
          </div>
          <div className={`${styles.dataTitle} ${styles.margin24}`}>Datos de contacto</div>
          <div className={`${styles.inputRow}`}>
            <div
              className={`${
                (screenSize !== 'phone' && styles.container50) || styles.container100
              }`}>
              <TextInput input={phoneInput.input} />
            </div>
            <div
              className={`${
                (screenSize !== 'phone' && styles.container50) || styles.container100
              }`}>
              <TextInput input={mailInput.input} />
            </div>
          </div>
          <div className={`${styles.dataTitle} ${styles.margin24}`}>Dirección</div>
          <div className={`${styles.inputRow}`}>
            <div
              className={`${
                (screenSize !== 'phone' && styles.container50) || styles.container100
              }`}>
              <TextInput input={addressInput.input} />
            </div>
            <div
              className={`${
                (screenSize !== 'phone' && styles.container50) || styles.container100
              }`}>
              <TextInput input={cityInput.input} />
            </div>
          </div>

          <div className={`${styles.inputRow} ${styles.marginTop}`}>
            <div
              className={`${
                (screenSize !== 'phone' && styles.container33) || styles.container100
              }`}>
              <TextInput input={stateInput.input}>
                <option value=''>Estado</option>
                {mxStates.map((val, key) => {
                  const { code = '', name = '' } = val;
                  return (
                    <option value={code} key={key}>
                      {name}
                    </option>
                  );
                })}
              </TextInput>
            </div>
            <div
              className={`${
                (screenSize !== 'phone' && styles.container33) || styles.container100
              }`}>
              <TextInput input={countryInput.input} />
            </div>
            <div
              className={`${
                (screenSize !== 'phone' && styles.container33) || styles.container100
              }`}>
              <TextInput input={postalcodeInput.input} />
            </div>
          </div>
          <div className={styles.policiesContainer}>
            <Checkbox
              className={styles.check}
              checked={acceptedPolicies}
              onClick={() => setAcceptedPolicies(!acceptedPolicies)}
            />
            <div className={styles.legalText}>
              <span>Acepto y estoy de acuerdo con las</span>
              <span
                className={styles.legalLink}
                onClick={() => window.open('https://www.terramarbrands.com.mx/privacy/')}>
                políticas de privacidad
              </span>
            </div>
          </div>
        </div>

        <div className={styles.cardHeader}>
          <div className={styles.cardTitle}>
            <i className={`material-icons-outlined ${styles.icon}`}>error_outline</i>
            Tu pedido será enviadl a:
          </div>
        </div>

        <div className={styles.cardContainer}>
          <div className={styles.consultantData}>
            <div className={styles.consultantRow}>
              <div className={styles.consultantTitle}>Nombre:</div>
              <div className={styles.consultantText}>{order?.nombreConsultora}</div>
            </div>

            <div className={styles.consultantRow}>
              <div className={styles.consultantTitle}>Kit:</div>
              <div className={styles.consultantText}>{order?.noKitAsignado}</div>
            </div>

            <div className={styles.consultantRow}>
              <div className={styles.consultantTitle}>Teléfono:</div>
              <div className={styles.consultantText}>{order?.telefonoConsultora}</div>
            </div>
          </div>
          <div className={styles.infoContainer}>
            <span className={styles.infoTitle}>Información: </span>
            <span className={styles.infoText}>
              Una vez terminado tu pedido, tu consultora en breve te contactará; es importante que
              guardes sus datos de contacto para futuras aclaraciones y/o solicitar información.
            </span>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            variant='contained'
            size='large'
            color='primary'
            className={`${styles.button} ${(buttonIsDisabled && styles.buttonDisabled) || null}`}
            onClick={async (e) => {
              if (!buttonIsDisabled) handleSubmit();
            }}>
            {(loading && <CircularProgress className={styles.circularProgress} />) ||
              'Solicitar mi pedido'}
          </Button>
        </div>
      </div>
    </div>
  );
}
