import React, { useEffect, useState } from 'react';
import styles from './index.module.sass';

//Dependencies
import superagent from 'superagent';
import CircularProgress from '@material-ui/core/CircularProgress';

const Videos = (props) => {
  const { device = {}, webservice } = props;
  const { screenSize = 'desktop' } = device;

  const [videos, setVideos] = useState([]);
  const [catSelected, setCatSelected] = useState(0);
  const [loading, setLoading] = useState(false);
  const [videoSelected, setVideoSelected] = useState(0);
  const [infoVideoSelected, setInfoVideoSelected] = useState({ enlace: '', texto: '' });
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    setLoading(true);
    superagent
      .get(`${webservice}/getVideos`)
      .set('Content-Type', 'application/json')
      .then((res) => {
        const { body = [] } = res;

        setLoading(false);
        setVideos(body);
        if (body.length > 0 && body[0].videos.length > 0) setInfoVideoSelected(body[0].videos[0]);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [webservice]);

  function closeVideo() {
    setShowVideo(false);
  }

  return (
    <div id={styles.Videos} className={styles[screenSize]}>
      {showVideo && (
        <div className={styles.fixedVideoContainer}>
          <div className={styles.overlay} onClick={() => closeVideo()}></div>
          <div className={styles.closeVideo} onClick={() => closeVideo()}>
            X
          </div>
          <div className={styles.videoCard}>
            <iframe
              width='95%'
              height='95%'
              src={`https://www.youtube.com/embed/${infoVideoSelected.enlace}?autoplay=1&mute=1`}
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen='allowfullscreen'></iframe>
          </div>
        </div>
      )}
      {(loading && (
        <div className={styles.loading}>
          <CircularProgress />
        </div>
      )) || (
        <>
          <div className={styles.categoriesContainer}>
            <div className={styles.categories}>
              {videos.map((val, key) => {
                return (
                  <div
                    className={`${styles.category} ${styles[catSelected === key]}`}
                    key={key}
                    onClick={() => {
                      setCatSelected(key);
                      setInfoVideoSelected(val.videos[0]);
                    }}>
                    {val.categoria}
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.thumbsContainer}>
            <div className={styles.thumbs}>
              {videos.length > 0 &&
                videos[catSelected].videos.length > 0 &&
                videos[catSelected].videos.map((val, key) => {
                  const { enlace = '', texto = '' } = val;

                  return (
                    <div
                      className={`${styles.thumbBox} ${styles[videoSelected === key]}`}
                      key={key}
                      onClick={() => {
                        setVideoSelected(key);
                        setInfoVideoSelected(val);
                        if (screenSize === 'phone') setShowVideo(true);
                      }}>
                      <div
                        className={styles.thumbImage}
                        style={{
                          backgroundImage: `url("https://terramarbrands.mx/images/webpage/videos/${enlace}.png")`,
                        }}></div>
                      <strong>{texto}</strong>
                    </div>
                  );
                })}
            </div>
          </div>
          {screenSize !== 'phone' && (
            <div className={styles.videoContainer}>
              <h2>{infoVideoSelected.texto}</h2>
              <iframe
                width='90%'
                height='450'
                src={`https://www.youtube.com/embed/${infoVideoSelected.enlace}?rel=0&amp;showinfo=0`}
                frameBorder='0'
                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen='allowfullscreen'
                className={styles.youtube}
                title='TMBVideos'></iframe>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Videos;
