import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.sass';

//Components
import Prefooter from './Prefooter';

//Images
import logo from '../../images/logo_header.png';
import MAIL from './icons/mail_icon.svg';
import WHATSAPP from './icons/whatsapp_icon.svg';
import PHONE from './icons/phone_icon.svg';
import LOCK from './icons/lock.svg';
import VERIFIED from './icons/verified.svg';
import FB from './icons/fb_icon.svg';
import YT from './icons/yt_icon.svg';

const Footer = (props) => {
  const { device } = props;
  const { screenSize } = device;
  const { t } = useTranslation('footer');

  const securityText = t('security').split('<br/>');

  return (
    <div id={styles.Footer} className={styles[screenSize]}>
      <Prefooter device={device} />
      <div className={styles.lastFooter}>
        <div className={styles.lastFooterColumns}>
          <div className={`${styles.footerColumn} ${styles.first}`}>
            <div className={styles.contactTitle}>{t('contactTitle')}</div>

            <div className={styles.contactData}>
              <img className={styles.footerIcon} src={MAIL} alt='' />
              <a
                href='mailto:callcenter@terramarbrands.com'
                target='_blank'
                without='true'
                rel='noopener noreferrer'>
                <span>{t('sendMail')}</span>
              </a>
            </div>

            <div className={styles.contactData}>
              <img className={styles.footerIcon} src={PHONE} alt='' />
              <a href='tel:3332681430' target='_blank' rel='noopener noreferrer'>
                <span>{t('phone')}</span>
              </a>
            </div>

            <div className={styles.contactData}>
              <img className={styles.footerIcon} src={WHATSAPP} alt='' />
              <a
                href='https://api.whatsapp.com/send?phone=525542477210'
                target='_blank'
                rel='noopener noreferrer'>
                <span>{t('whatsapp')}</span>
              </a>
            </div>

            <div className={styles.contactData}>
              <a
                href='https://admin.terramarbrands.com.mx/'
                target='_blank'
                rel='noopener noreferrer'>
                <span>{t('admin')}</span>
              </a>
            </div>
          </div>
          <div className={`${styles.footerColumn} ${styles.logoContainer}`}>
            {screenSize !== 'phone' && (
              <img className={styles.logo} src={logo} alt='Terramar Logo' />
            )}
            <div className={styles.securityIcons}>
              <img src={VERIFIED} alt='' />
              <img src={LOCK} alt='' />
            </div>
            {securityText.map((val, key) => {
              return (
                <div className={styles.securityText} key={key}>
                  {val}
                </div>
              );
            })}
          </div>
          <div className={`${styles.footerColumn} ${styles.socialColumn}`}>
            <div className={styles.socialIcons}>
              <span>{t('socialTitle')}</span>
              <div>
                <a href={t('facebookLink')} target='_blank' rel='noopener noreferrer'>
                  <img src={FB} alt='Terramar facebook' />
                </a>
                <a href={t('youtubeLink')} target='_blank' rel='noopener noreferrer'>
                  <img className={styles.last} src={YT} alt='Terramar youtube' />
                </a>
              </div>
            </div>
          </div>

          <div className={styles.chatRedes}>
            <div className={styles.iconChat}>
              <a
                href='https://m.me/terramarbrandscorporativo'
                target='_blank'
                rel='noopener noreferrer'>
                <img
                  className={styles.iconImage}
                  src='https://webimages.terramarbrands.com.mx/webpage/general/facebook_messenger_icono.png'
                  alt=''
                />
              </a>
            </div>

            <div className={styles.iconChat}>
              <a
                href='https://api.whatsapp.com/send?phone=523329858257&text=Hola!%20Quiero%20recibir%20información'
                target='_blank'
                rel='noopener noreferrer'>
                <img
                  className={styles.iconImage}
                  src='https://webimages.terramarbrands.com.mx/webpage/general/whatsapp_icono.png'
                  alt=''
                />
              </a>
            </div>
          </div>
        </div>
        <div className={styles.legal}>
          {t('rightsReserved')}
          <a href='/privacy' className={styles.link}>
            {t('privacyNotice')}{' '}
          </a>{' '}
          <a href='/terms' className={styles.link}>
            {t('terms')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
