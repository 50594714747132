import React from 'react';
import styles from './index.module.sass';

const Stripe = (props) => {
  const { margin } = props;

  return (
    <div
      className={styles.stripe}
      style={{
        margin: margin || '0px',
      }}></div>
  );
};

export default Stripe;
