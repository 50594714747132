import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.sass';

const Contact = (props) => {
  const { device } = props;
  const { screenSize } = device;
  const { t } = useTranslation('contact');
  const ubicaciones = t('ubicaciones', { returnObjects: true });

  return (
    <div id={styles.Joinup} className={styles[screenSize]}>
      <div className={styles.container}>
        {ubicaciones.map((val, key) => {
          return (
            <div className={styles.boxCity} key={key}>
              <div className={styles.headerBox}>
                <img className={styles.city} src={val.img} alt='' />
                <h2>&nbsp;{val.ciudad}</h2>
              </div>
              <div className={styles.bodyBox}>
                <b>&nbsp;{t('address')}</b>
                <br />
                &nbsp;{val.direccion}
                <br />
                <b>&nbsp;{t('tel')}</b>
                <br />
                &nbsp;{val.telefonos}
              </div>
              <br />
              <div>
                <iframe
                  src={val.mapa}
                  title='mapa'
                  frameBorder='0'
                  allowFullScreen
                  className={styles.iframe}></iframe>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Contact;
