import React, { Suspense, useEffect } from 'react';
import useDevice from './hooks/useDevice';
import { Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

//Components
import Loadingpage from './components/Loadingpage';
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Footer from './components/Footer';
//import Consultancy from './components/Consultancy';
import Catalog from './components/Catalog';
import Career from './components/Career';
import Videos from './components/Videos';
import Joinus from './components/Joinus';
import Products from './components/Products';
import Product from './components/Product';
import PrivacyNotice from './components/Footer/PrivacyNotice';
import TermsOfUse from './components/Footer/TermsOfUse';
import Responsibility from './components/Footer/Responsibility';
import Faq from './components/Footer/Faq';
import Contact from './components/Footer/Contact';
import RegistrationForm from './components/RegistrationForm';
import CreandoSonrisas from './components/CreandoSonrisas';
import Contador from './components/Contador';
import HeaderAppRedirect from './components/HeaderAppRedirect';
import IpaperOrder from './components/IpaperOrder';
import DeleteAccount from './components/DeleteAccount';

const webservice = 'https://api.terramarbrandsusa.com/wsTerramar/Service1.svc';
//const adminWebservice = 'https://admin.terramarbrands.mx';

// function getCookie(name) {
//   var cookieArr = document.cookie.split(';');
//   for (var i = 0; i < cookieArr.length; i++) {
//     var cookiePair = cookieArr[i].split('=');
//     if (name === cookiePair[0].trim()) {
//       return decodeURIComponent(cookiePair[1]);
//     }
//   }
//   return null;
// }

const Terramar = ({ history, userAgent }) => {
  const device = useDevice({ userAgent });
  const { i18n = {} } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage('es');
  }, [i18n]);

  return (
    <div className='App'>
      <Route
        path='/'
        render={(props) => {
          return (
            <>
              {device?.device === 'phone' && (
                <HeaderAppRedirect {...props} device={device} history={history} />
              )}
              <Header {...props} device={device} history={history} />
            </>
          );
        }}
      />
      <Switch>
        <Route
          path='/about'
          render={(props) => {
            return <About {...props} device={device} history={history} />;
          }}
        />
        {/* <Route
          path='/consultancy'
          render={(props) => {
            return (
              <Consultancy
                {...props}
                device={device}
                history={history}
                webservice={webservice}
                adminWebservice={adminWebservice}
                getCookie={getCookie}
              />
            );
          }}
        /> */}
        <Route
          path='/privacy'
          render={(props) => {
            return (
              <PrivacyNotice {...props} device={device} history={history} webservice={webservice} />
            );
          }}
        />
        <Route
          path='/terms'
          render={(props) => {
            return (
              <TermsOfUse {...props} device={device} history={history} webservice={webservice} />
            );
          }}
        />
        <Route
          path='/responsibility'
          render={(props) => {
            return (
              <Responsibility
                {...props}
                device={device}
                history={history}
                webservice={webservice}
              />
            );
          }}
        />
        <Route
          path='/delete-account'
          render={(props) => {
            return (
              <DeleteAccount {...props} device={device} history={history} webservice={webservice} />
            );
          }}
        />
        <Route
          path='/faq'
          render={(props) => {
            return <Faq {...props} device={device} history={history} webservice={webservice} />;
          }}
        />
        <Route
          path='/contact'
          render={(props) => {
            return <Contact {...props} device={device} history={history} webservice={webservice} />;
          }}
        />
        <Route
          path='/catalog'
          render={(props) => {
            return <Catalog {...props} device={device} history={history} />;
          }}
        />
        <Route
          path='/career'
          render={(props) => {
            return <Career {...props} device={device} history={history} />;
          }}
        />
        <Route
          path='/videos'
          render={(props) => {
            return <Videos {...props} device={device} history={history} webservice={webservice} />;
          }}
        />
        <Route
          path='/creandoSonrisas'
          render={(props) => {
            return (
              <CreandoSonrisas
                {...props}
                device={device}
                history={history}
                webservice={webservice}
              />
            );
          }}
        />
        <Route
          path='/joinus'
          render={(props) => {
            return <Joinus {...props} device={device} history={history} webservice={webservice} />;
          }}
        />

        <Route
          path='/products/product/:id'
          render={(props) => {
            return <Product {...props} device={device} history={history} webservice={webservice} />;
          }}
        />
        <Route
          path='/products/:catid?'
          render={(props) => {
            return (
              <Products {...props} device={device} history={history} webservice={webservice} />
            );
          }}
        />

        <Route
          path='/registrationForm'
          render={(props) => {
            return (
              <RegistrationForm
                {...props}
                device={device}
                history={history}
                webservice={webservice}
              />
            );
          }}
        />
        <Route
          path='/contador'
          render={(props) => {
            return (
              <Contador {...props} device={device} history={history} webservice={webservice} />
            );
          }}
        />
        <Route
          path={'/digitalorder/:orderId'}
          render={(props) => {
            return (
              <IpaperOrder {...props} history={history} webservice={webservice} device={device} />
            );
          }}
        />
        <Route
          path='/'
          render={(props) => {
            return <Home {...props} device={device} history={history} webservice={webservice} />;
          }}
        />
      </Switch>
      <Footer device={device} history={history} />
    </div>
  );
};

function App({ history, userAgent = null }) {
  return (
    <Suspense fallback={<Loadingpage />}>
      <Terramar history={history} userAgent={userAgent} />
    </Suspense>
  );
}

export default App;
