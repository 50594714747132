import React from 'react';

// Styles
import styles from './index.module.sass';

// Images
import appIcon from '../../images/icono-terramar-eu.png';

export default function HeaderAppRedirect(props) {
  const { device } = props;
  const { os, width } = device;

  function appRedirect() {
    if (os === 'Android')
      window.open('https://play.google.com/store/apps/details?id=com.icom.terramar');
    else window.open('https://apps.apple.com/mx/app/terramar-brands-mx/id1492884080');
  }

  return (
    <div className={styles.appRedirectContainer}>
      <div className={styles.redirectIcon}>
        <img src={appIcon} alt='Terramar App Icon' className={styles.appIcon} />
      </div>
      <div className={styles.redirectText}>
        <div>
          <div
            className={styles.redirectTitle}
            style={{
              fontSize: (width < 400 && '14px') || '15px',
            }}>
            Descarga nuestra aplicación
          </div>
          <div
            className={styles.redirectSubtitle}
            style={{
              fontSize: (width < 400 && '13px') || '14px',
            }}>
            Terramar Brands
          </div>
        </div>
        <div
          className={styles.redirectStore}
          style={{
            fontSize: (width < 400 && '13px') || '14px',
          }}>
          {(os === 'Android' && 'OBTENER - App Store') || 'OBTENER - Google Play'}
        </div>
      </div>
      <div className={styles.redirectButton} onClick={() => appRedirect()}>
        Ver
      </div>
    </div>
  );
}
