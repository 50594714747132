import React from 'react';
import styles from './index.module.sass';
import { useTranslation } from 'react-i18next';

const Schools = (props) => {
  const { screenSize } = props;
  const { t } = useTranslation('creatingSmiles');
  const schools = t('schools', { returnObjects: true });

  return (
    <div id={styles.Schools} className={styles[screenSize]}>
      <div className={styles.title}>
        {t('titleSchools')} <br /> {t('subTitleSchools')}
      </div>
      <div className={`${styles.schools} ${styles[screenSize]}`}>
        {schools.map((val, key) => {
          return (
            <div key={key} className={`${styles.bloqueSchool} ${styles[screenSize]}`}>
              <div className={styles.divBlue}>
                <div className={styles.label}>{val.school}</div>
                {val.detail.map((value, key) => {
                  return (
                    <div className={styles.button} key={key}>
                      <i className={`material-icons ${styles.icon}`}>{value.icon}</i>
                      <label>&nbsp;&nbsp;{value.text}</label>
                    </div>
                  );
                })}
              </div>

              <div className={styles.divImage}>
                <img
                  src={`https://webimages.terramarbrands.com.mx/webpage/creandoSonrisas/${val.image}`}
                  alt=''
                  className={`${styles.img} ${styles[screenSize]}`}
                />
              </div>
            </div>
          );
        })}
        {screenSize === 'desktop' && (
          <div className={styles.divGray}>
            <div className={styles.labelGray}>Próximamente</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Schools;
