import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.sass';

//Components
import HeroBanner from '../../HeroBanner';
import Stripe from '../../Stripe';
import Title from '../../Title';

const Responsibility = (props) => {
  const { device } = props;
  const { screenSize } = device;
  const { t } = useTranslation('responsibility');

  return (
    <div id={styles.Joinup} className={styles[screenSize]}>
      <HeroBanner device={device} desktopImg={t('bannerDesktop')} mobileImg={t('bannerMobile')} />
      <Title device={device} title={t('title')} subtitle={t('subTitle')} />
      <Stripe device={device} />
      <div className={styles.Text}>
        <br />
        <b>TERRAMAR BRANDS Y SU RESPONSABILIDAD SOCIAL CON MÉXICO </b>
        <br /> <br />
        TERRAMAR BRANDS, además de buscar ser una empresa mexicana líder en la Industria de la Venta
        Directa, tanto por sus productos cosméticos y de belleza de alta calidad, como por su
        inmejorable Plan de Carrera para Líderes y consultoras, también asume la Responsabilidad
        Social como un compromiso con el desarrollo y bienestar de las personas, enfocándose
        principalmente en Niños y Familias de escasos recursos, y que se encuentren en situaciones
        más marginadas.
        <br />
        <br />
        <br />
        <b>OBJETIVO DEL PROYECTO</b>
        <br />
        <br />
        El proyecto "Creando Sonrisas con Terramar Brands y Construyendo" tiene como finalidad
        principal hacer Feliz y Transformar la Vida del Mayor Número de Personas Posibles, al
        entregar a aquellas comunidades marginadas y de escasos recursos Aulas, donde los niños
        puedan estudiar de una manera digna y segura.
        <br />
        <br />
        <br />
        <b>ALCANCE DEL PROYECTO</b>
        <br />
        <br />
        Es a través de diferentes programas, como el del año 2018, cuando los Terremotos que
        sacudieron a México, se buscó trabajar de la mano con la Asociación Construyendo AC, para
        donar casas a familias que lo habían perdido todo, como resultado de esta catástrofe
        natural. Y una vez más pensando en ayudar y continuar con nuestra Filosofía de{' '}
        <b>"HACER FELICES AL MAYOR NÚMERO DE PERSONAS POSIBLES"</b>, Terramar Brands y Construyendo
        se suman, y ahora lanzamos nuestro Proyecto de Responsabilidad Social{' '}
        <b>"Creando Sonrisas con Terramar Brands y Construyendo"</b> enfocada a los NIÑOS, buscando
        impactar positivamente a la sociedad, el cual se verá materializado con la construcción de:
        <br />
        <br />
        <b>&nbsp;&nbsp;• Aulas</b> que proporcionan un ambiente apropiado para el aprendizaje de los
        estudiantes. <br />
        <b>&nbsp;&nbsp;• Bibliotecas</b> y centros de cómputo que facilitan el acceso a información,
        investigación y lectura. <br /> <b>&nbsp;&nbsp;• Centros Comunitarios</b> para que la
        comunidad se reúna y tenga acceso a diferentes actividades.
        <br />
      </div>
      <br />
    </div>
  );
};

export default Responsibility;
