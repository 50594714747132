import React, { useState, useCallback, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import superagent from 'superagent';

import styles from './index.module.sass';

const urlImages = process.env.REACT_APP_BANNER_IMAGES;

const urlPDFs = process.env.REACT_APP_BANNER_DOCS;

const Banner = (props) => {
  const { device } = props;
  const screenSize = device.screenSize;
  const [banner, setBanner] = useState([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    appendDots: (dots) => (
      <div
        style={{
          bottom: '65px',
        }}>
        <ul style={{ margin: '0px' }}> {dots} </ul>
      </div>
    ),
  };

  const getBanners = useCallback(async () => {
    const bannerURL = process.env.REACT_APP_BANNER;

    await superagent
      .get(bannerURL)
      .set('Content-Type', 'application/json')
      .then((res) => {
        if (res?.body) setBanner(res.body);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getBanners();
  }, [getBanners]);

  return (
    <Slider {...settings}>
      {banner &&
        banner.length > 0 &&
        banner.map((val, key) => {
          const imgMob = val.image_mob;
          const imgDesk = val.image;
          const linkText = val.linkText;
          const utlLink = val.url;
          if (screenSize !== 'phone' && !imgDesk) return null;
          if (screenSize === 'phone' && !imgMob) return null;
          return (
            <div className={styles.slideContainer} key={key}>
              <img
                src={`${
                  (screenSize === 'phone' && `${urlImages}${imgMob}`) || `${urlImages}${imgDesk}`
                }`}
                alt=''
              />
              {val.url && (
                <div className={styles.goTo}>
                  <a href={`${urlPDFs}${utlLink}`} target='_blank' rel='noopener noreferrer'>
                    {linkText}
                  </a>
                </div>
              )}
            </div>
          );
        })}
    </Slider>
  );
};

export default Banner;
