import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.sass';

//Components
import HeroBanner from '../HeroBanner';
import Stripe from '../Stripe';
import Title from '../Title';
import FindLeader from './findLeader';
import ContactForm from './contactForm';

const Joinup = (props) => {
  const { device, webservice } = props;
  const { screenSize } = device;
  const { t } = useTranslation('joinus');
  const [leaderSelected, setLeaderSelected] = useState({});

  const options = t('options', { returnObjects: true });
  const legal = t('legal', { returnObjects: true });

  return (
    <div id={styles.Joinup} className={styles[screenSize]}>
      <HeroBanner device={device} desktopImg={t('bannerDesktop')} mobileImg={t('bannerMobile')} />
      <Title device={device} title={t('title')} subtitle={t('subtitle')} />
      <Stripe device={device} />
      <div className={styles.container}>
        <div className={styles.headerTitle}>{t('headerTitle')}</div>
        <div className={styles.optionsContainer}>
          {options.map((val, key) => {
            const { image = '', title = '', text = '' } = val;
            return (
              <div className={styles.option} key={key}>
                <div className={styles.image}>
                  <img src={image} alt='' />
                </div>
                <div className={styles.title}>{`${key + 1}. ${title}:`}</div>
                <div className={styles.text}>{text}</div>
              </div>
            );
          })}
        </div>
        <div className={styles.legal}>
          {legal.map((val, key) => {
            return <div key={key}>{val}</div>;
          })}
        </div>
        <div className={styles.forms}>
          <FindLeader
            screenSize={screenSize}
            webservice={webservice}
            setLeaderSelected={setLeaderSelected}
          />
          <ContactForm
            screenSize={screenSize}
            webservice={webservice}
            leaderSelected={leaderSelected}
          />
        </div>
      </div>
    </div>
  );
};

export default Joinup;
