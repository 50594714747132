import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../index.module.sass';
import HeroBanner from '../../HeroBanner';
import Stripe from '../../Stripe';
import Title from '../../Title';

const Faq = (props) => {
  const { device } = props;
  const { screenSize } = device;
  const { t } = useTranslation('faq');
  const [faq, setfaq] = useState('carrera');
  const [seeAnswer, setseeAnswer] = useState(0);
  const menu = t('menu', { returnObjects: true });
  const questions = t('questions', { returnObjects: true });

  return (
    <div id={styles.Joinup} className={styles[screenSize]}>
      <HeroBanner device={device} desktopImg={t('bannerDesktop')} mobileImg={t('bannerMobile')} />
      <Title device={device} title={t('title')} subtitle={t('subTitle')} />
      <Stripe device={device} />
      <div className={` ${styles.Text} ${styles[screenSize]}`}>
        <br />
        <div className={` ${styles.boxCategory} ${styles[screenSize]}`}>
          <div className={styles.titleCategory}>
            <br />
            {t('subText')}
          </div>
          {menu.map((val, key) => {
            return (
              <div
                className={val.code === faq ? styles.categoryUnderline : styles.category}
                key={key}
                onClick={() => {
                  setfaq(val.code);
                }}>
                {val.name}
              </div>
            );
          })}
        </div>

        <div className={styles.answer}>
          <p className={styles.titleTerra}>{t('textBloque2')}</p>
          {questions.map((val, key) => {
            return (
              <div key={key} className={styles.contentAnswer}>
                <div className={styles.answerBox2}>
                  {val.code === faq ? (
                    <p
                      key={key}
                      onClick={() => {
                        setseeAnswer(key);
                      }}>
                      <img
                        src='https://webimages.terramarbrands.com.mx/webpage/general/mini_logo.svg'
                        alt=''
                        className={styles.image}
                      />
                      {val.pregunta}
                      <br />
                      {key === seeAnswer ? (
                        <label className={styles.resp}>
                          <b>{t('answer')}</b>
                          <br />
                          {val.respuesta.split('<br>')}
                        </label>
                      ) : (
                        <label className={styles.none}>&nbsp;</label>
                      )}
                    </p>
                  ) : (
                    <div className={styles.none}>&nbsp;</div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Faq;
