import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.sass';
import { Link } from 'react-router-dom';

// Components
import Menu from '../Menu';

// Dependencies
import buttonFormROP from '../VerifyButtonROP/buttonROP.js';

// Images
import FlagUSA from '../../images/flag_usa.png';
import FlagMEX from '../../images/flag_mexico.png';
import question from '../../icons/help_icon.svg';
//import cart from '../../icons/shop_icon.svg';
import logo from '../../images/logo_header.png';

const Header = (props) => {
  const { device, match, history } = props;
  const { screenSize } = device;
  const { i18n, t } = useTranslation(['home']);
  const [open, setOpen] = useState(false);
  const [optionSelected, setOptionSelected] = useState('');
  const [languageOpen, setLanguageOpen] = useState(false);
  const [buttonROP, setButtonROP] = useState(false);

  function changeLanguage(lang) {
    i18n.changeLanguage(lang);
    setLanguageOpen(false);
  }

  useEffect(() => {
    async function loadROP() {
      const response = await buttonFormROP();
      setButtonROP(response);
    }
    loadROP();
  }, [buttonROP]);

  function LanguageSelect() {
    return (
      <div className={styles.languageSelect}>
        <div
          className={styles.languageSelected}
          onClick={() => {
            setLanguageOpen(!languageOpen);
          }}>
          {(i18n.language === 'es' && 'Español') ||
            (i18n.language === 'es-US' && 'Español') ||
            'English'}
        </div>
        <div className={styles.optionsContainer}>
          <div className={`${styles.optionsContent} ${styles[languageOpen]}`}>
            <div className={`${styles.languageOption}`} onClick={() => changeLanguage('es')}>
              Español
            </div>
            <div className={`${styles.languageOption}`} onClick={() => changeLanguage('en')}>
              English
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id={styles.Header} className={styles[screenSize]}>
      {screenSize !== 'phone' && (
        <div>
          {buttonROP ? (
            <div className={styles.cssROP}>
              <div
                className={styles.button}
                onClick={() => window.open('/registrationForm/', '_self')}>
                REGISTRATE AQUÍ
              </div>
            </div>
          ) : (
            <div>&nbsp;</div>
          )}

          <div className={styles.headerActions}>
            <div className={styles.countryFlags}>
              <div className={styles.flag}>
                <a href='https://www.terramarbrands.com.mx/'>
                  <img src={FlagMEX} alt='bandera de México' className={styles.flagImg} />
                </a>
              </div>
              <div className={styles.flag}>
                <a
                  href='https://www.terramarbrandsusa.com/'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <img src={FlagUSA} alt='bandera de USA' className={styles.flagImg} />
                </a>
              </div>
            </div>
            <div className={styles.actions}>
              <Link to='/faq' className={styles.preguntas}>
                {t('faq')} <img src={question} alt='' />
              </Link>
              <div
                className={styles.button}
                onClick={() => window.open('https://carrito.terramarbrands.com.mx', '_self')}>
                {t('cartName')}
              </div>
              {screenSize === 'test' && <LanguageSelect />}
            </div>
          </div>
        </div>
      )}
      <div className={`${styles.logo} ${styles[screenSize]}`}>
        {screenSize === 'phone' && (
          <i className='material-icons' onClick={() => setOpen(true)}>
            menu
          </i>
        )}
        <img src={logo} alt='Logo Terramar Brands' />
        {screenSize === 'phone' && (
          <div className={styles.flagsMobile}>
            <div className={styles.countryFlags}>
              <div className={styles.flagMob}>
                <a href='https://www.terramarbrands.com.mx/'>
                  <img src={FlagMEX} alt='bandera de México' className={styles.flagImgMob} />
                </a>
              </div>
              <div className={styles.flagMob}>
                <a
                  href='https://www.terramarbrandsusa.com/'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <img src={FlagUSA} alt='bandera de USA' className={styles.flagImgMob} />
                </a>
              </div>
            </div>
          </div>
        )}
        {screenSize === 'test' && <LanguageSelect />}
      </div>
      <Menu
        device={device}
        open={open}
        setOpen={setOpen}
        optionSelected={optionSelected}
        setOptionSelected={setOptionSelected}
        match={match}
        history={history}
      />
    </div>
  );
};

export default Header;
