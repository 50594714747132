import superagent from 'superagent';

export default async function buttonFormROP() {
  let response = false;

  await superagent
    .get('https://terramarbrands.mx/wsterramar/Service1.svc/RopHabilitado?accion=CONSULTAR')
    .set('Content-Type', 'application/json')
    .then((res) => {
      const { body = {} } = res;
      if (body.typeMessage === 'success') {
        if (body.message === 'S') {
          response = true;
        } else {
          response = false;
        }
        if (parseInt(body.dato1) > 1000) {
          response = false;
        }
      } else {
        console.log(body.message);
        response = false;
      }
    })
    .catch((err) => {
      console.log(err);
    });

  return response;
}
