import React, { useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './index.module.sass';

//Dependencies
import superagent from 'superagent';

//Components
import HeroBanner from '../HeroBanner';
import Title from '../Title';
import { CircularProgress } from '@material-ui/core';

const Products = (props) => {
  let { catid = 1 } = useParams();
  const { device, webservice, history } = props;
  const { screenSize } = device;
  const { t } = useTranslation('products');
  const [departments, setDepartments] = useState([]);
  const [deptSelected, setDeptSelected] = useState(0);
  const [subSelected, setSubtSelected] = useState(0);
  const [productsGrid, setProductsGrid] = useState([]);
  const [loading, setLoading] = useState(false);

  const getDepartments = useCallback(() => {
    const url = `${webservice}/getDeptos`;
    superagent
      .get(url)
      .set('Content-Type', 'application/json')
      .then((res) => {
        const { body = {} } = res;
        let dpts = [];
        for (let i = 0; i < body.length; i++) {
          const { menu = '' } = body[i];
          if (menu === '3') dpts.push(body[i]);
        }
        setDepartments(dpts);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [webservice]);

  const getDescriptions = useCallback(
    (dep, subdep) => {
      setLoading(true);
      const url = `${webservice}/getDescripciones?depto=${dep}&subdepto=${subdep}`;
      superagent
        .get(url)
        .set('Content-Type', 'application/json')
        .then((res) => {
          const { body = {} } = res;
          setProductsGrid(body);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [webservice],
  );

  useEffect(() => {
    getDepartments();
    getDescriptions(catid, 0);
  }, [getDepartments, getDescriptions, catid]);

  return (
    <div id={styles.Products} className={styles[screenSize]}>
      {screenSize !== 'desktop' && (
        <>
          <HeroBanner
            device={device}
            desktopImg={t('bannerDesktop')}
            mobileImg={t('bannerMobile')}
          />
          <Title device={device} title={t('title')} subtitle={t('subtitle')} />
        </>
      )}

      {screenSize === 'desktop' && (
        <div
          id={styles.HeroBanner}
          className={`${styles.inblock} ${styles.textStyle} ${styles.mainImg}`}>
          <div className={styles.h1}>{t('title')}</div>
          <div className={styles.h5}>{t('subtitle')}</div>
        </div>
      )}

      <div className={styles.container}>
        <div className={styles.menu}>
          <div className={styles.menuHeader}>
            <i className={`material-icons ${styles.menuIcon}`}>list</i>
            <div className={styles.title}>Categorias</div>
          </div>
          <div className={styles.menuContainer}>
            {departments.map((val, key) => {
              const { depto = '0', nombre = '', secciones = [] } = val;
              return (
                <div key={key} className={styles.nameContainer}>
                  <div
                    className={`${styles.deptName} ${styles[depto === deptSelected]}`}
                    onClick={() => {
                      setDeptSelected(depto);
                      setSubtSelected(0);
                      getDescriptions(depto, 0);
                    }}>
                    <span>{nombre}</span>
                    <i className={`material-icons`}>navigate_next</i>
                  </div>

                  <div className={`${styles.subitems} ${styles[depto === deptSelected]}`}>
                    {secciones.map((subval, subkey) => {
                      const { subdepto = '0', nombre = '' } = subval;
                      return (
                        (depto === deptSelected && (
                          <div
                            key={subkey}
                            className={`${styles.subitem} ${styles[subdepto === subSelected]}`}
                            onClick={() => {
                              setSubtSelected(subdepto);
                              getDescriptions(depto, subdepto);
                            }}>
                            - {nombre}
                          </div>
                        )) ||
                        null
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {(loading && (
          <div className={styles.leftContainer}>
            <div className={styles.loading}>
              <CircularProgress />
            </div>
          </div>
        )) || (
          <div className={styles.leftContainer}>
            <div className={styles.productsContainer}>
              {(productsGrid.length > 0 &&
                productsGrid.map((val, key) => {
                  const { clave = '', producto = '' } = val;
                  return (
                    <div
                      className={styles.productContainer}
                      key={key}
                      onClick={() => {
                        history.push(`products/product/${clave}`);
                      }}>
                      <div className={styles.productContent}>
                        <div className={styles.productImage}>
                          <img
                            src={`https://webimages.terramarbrands.com.mx/shopping-cart/cart-products-gray/${clave}.jpg`}
                            alt={producto}
                          />
                        </div>
                        <div className={styles.productName}>{producto}</div>
                      </div>
                    </div>
                  );
                })) || <div className={styles.loading}>No hay productos</div>}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Products;
