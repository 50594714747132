import styles from './index.module.sass';

export default function DeleteAccount() {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Eliminar cuenta</div>
      <div className={styles.text}>
        Para solicitar la eliminación de tu cuenta, es necesario que te comuniques a nuestra area de
        Call Center al número <a href='tel:3332681430'>33 32 68 14 30</a> o enviarnos un correo a{' '}
        <a href='mailto:callcenter@terramarbrands.com'>callcenter@terramarbrands.com</a> solicitando
        la baja permanente.
      </div>
    </div>
  );
}
