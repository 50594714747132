import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.sass';
import Pestanas from './Pestanas';

const About = (props) => {
  const { device = {} } = props;
  const { screenSize = 'desktop' } = device;
  const { t } = useTranslation('about');
  return (
    <div id={styles.About} className={styles[screenSize]}>
      {screenSize !== 'phone' ? (
        <div className={`${styles.inblock} ${styles.textStyle} ${styles.mainImg}`}>
          <h1>{t('mision')}</h1>
          <h5>{t('misionText')}</h5>
        </div>
      ) : (
        <>
          <div className={`${styles.inblock} ${styles.textStyle} ${styles.mainImg}`}>
            <h1>&nbsp;</h1>
          </div>
          <div className={`${styles.inblock} ${styles.textStyle}`}>
            <h1>{t('mision')}</h1>
            <h5>{t('misionText')}</h5>
          </div>
        </>
      )}

      <Pestanas device={device} />
      <div className={styles.flexblock}>
        <div className={`${styles.historia} ${styles.inblockmc}`}>
          <h2>{t('history.title')}</h2>
          <h5>{t('history.text')}</h5>
        </div>
      </div>
    </div>
  );
};

export default About;
