import React, { Suspense } from 'react';

// Styles
import styles from './index.module.sass';

// Images
import LOGO from '../../images/logo_header.png';

export default function SimpleHeader() {
  return (
    <Suspense fallback={<div />}>
      <div className={styles.orderTopHeader}>
        <div className={styles.logo}>
          <img src={LOGO} alt='logo' />
        </div>
      </div>
    </Suspense>
  );
}
